<template>
  <div
    v-if="errors.length"
    class="error-alert form-errors block bg-destructive border-0 p-4 rounded-lg"
  >
    <Icon
      type="exclamation-triangle"
      class="block text-destructive-emphasis h-4 w-4 mb-1"
    />
    <template v-if="validErrors.length">
      <p class="mb-0">
        <b class="text-destructive-emphasis text-base">Please correct the following errors:</b>
      </p>
      <ul class="mt-2 pl-6">
        <li
          v-for="(error, errorIndex) in validErrors"
          :key="errorIndex"
          class="text-destructive-emphasis text-base font-normal"
          data-cy="validation-error-message"
          v-html="error.message || error.error || error"
        />
      </ul>
    </template>
    <template v-else>
      <p class="mb-0">
        <b class="text-destructive-emphasis text-base">Something went wrong. Please try again.</b>
      </p>
    </template>
  </div>
</template>

<script>
import { isEmpty } from 'lodash';
import Icon from '@/components/ui/Icon';

export default {
  name: 'ValidationError',
  components: {
    Icon,
  },
  props: {
    errors: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    validErrors() {
      // There can be errors that are just empty objects, so we need to filter them out
      return this.errors.filter((error) => !isEmpty(error));
    },
  },
};
</script>

<style lang="scss">
</style>
