<template>
  <div class="max-w-xl">
    <div id="api-loaded">
      <p class="text-default text-base mb-6">
        The following are your credentials for using the <a
          href="https://docs.knack.com/docs/introduction-to-the-api"
          target="_blank"
          class="underline text-default font-semibold"
          style="display: inline-block"
        >
          Knack API
        </a>.
      </p>

      <div class="mb-6">
        <span class="block font-medium text-sm text-default">Application ID</span>
        <div class="mt-2 flex items-center">
          <p class="mb-0 text-emphasis text-base mr-2">
            {{ applicationId }}
          </p>
        </div>
        <div class="mt-2">
          <CopyButton
            class="w-30"
            :text-to-copy="applicationId"
            button-text="Copy ID"
          />
        </div>
      </div>

      <div>
        <span class="block font-medium text-sm text-default">API Key</span>
        <div class="mt-2 relative inline-block">
          <input
            v-model="apiKey"
            class="w-[480px] !hover:border-default"
            disabled
            :type="showApiKey ? 'text': 'password'"
          >
          <span class="absolute inset-y-3 flex cursor-pointer items-center right-3">
            <Icon
              class="h-6 w-6"
              :type="showApiKey ? 'eye' : 'eye-slash'"
              @click="handleToggleApiKeyVisibility"
            />
          </span>
        </div>
        <p
          v-if="isAppHipaa"
          class="mb-0 mt-2 text-sm max-w-[480px]"
        >
          This is a HIPAA specific Knack plan, and we would like to remind you of your responsibility
          to verify that any systems which you integrate with must be HIPAA compliant. You may need
          to execute a Business Associate Agreement (BAA) with the owners of any external systems that
          receive data from this application.
        </p>
      </div>

      <div class="flex mt-3 mb-6">
        <CopyButton
          class="w-40"
          :text-to-copy="apiKey"
          button-text="Copy API Key"
        />
        <button
          class="flex gap-1 p-3 rounded-lg border border-solid border-default bg-white leading-none
            hover:bg-brand-50 hover:border-brand-600 text-emphasis text-base font-medium mr-2"
          @click="showRequestApiKeyModal = true"
        >
          <Icon
            class="h-4 w-4"
            type="add"
          />
          <span>
            Generate New API Key
          </span>
        </button>
      </div>

      <div>
        <button
          class="button medium save bg-gradient-primary rounded-lg border-0 p-3 text-base leading-4 font-medium ml-auto"
          @click="showApiUsageModal = true"
        >
          View API Usage
        </button>
      </div>
    </div>

    <ConfirmModal
      v-if="showRequestApiKeyModal"
      confirm-type="confirm"
      confirm-text="Request Key"
      title="Request a New API key?"
      @confirm="setApiKey"
      @cancel="showRequestApiKeyModal = false"
      @close="showRequestApiKeyModal = false"
    >
      <p>All existing API calls will need to be updated with this new key.</p>
    </ConfirmModal>

    <Modal
      v-if="showApiUsageModal"
      title="API Usage"
      size="smallish"
      @close="showApiUsageModal = false"
    >
      <p class="text-default text-base mb-1">
        Current Plan: <strong>{{ productPlan }}</strong>: daily limit is <strong>{{ apiLimit }}</strong> requests.
      </p>
      <p class="text-xs text-default mb-6">
        The daily usage counts are reset each day at 12:01 AM GMT.
      </p>
      <div class="kn-table kn-table-wrapper">
        <table
          id="api-usage-table"
          class="kn-responsive kn-table-table is-bordered is-striped"
        >
          <thead>
            <tr>
              <th class="date">
                <span class="table-fixed-label">
                  <a
                    address="true"
                    class="kn-sort text-default font-medium"
                  >
                    <span class="kn-sort-icon" />Date (UTC)
                  </a>
                </span>
              </th>
              <th class="count">
                <span class="table-fixed-label">
                  <a
                    address="true"
                    class="kn-sort text-default font-medium"
                  >
                    <span class="kn-sort-icon" />Number of Calls
                  </a>
                </span>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="usage in apiUsage">
              <td>
                <span>{{ usage.date }}</span>
              </td>
              <td>
                <span>{{ usage.count }}</span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <a
        class="underline text-default font-medium text-base mt-6"
        href="https://www.knack.com/api-limits/"
        target="_blank"
      >
        Learn more about daily limits
      </a>
    </Modal>
  </div>
</template>

<script>
import get from 'lodash/get';
import ConfirmModal from '@/components/ui/ConfirmModal';
import Modal from '@/components/ui/Modal';
import Icon from '@/components/ui/Icon';
import RequestUtils from '@/components/util/RequestUtils';
import CopyButton from '@/components/ui/CopyButton';

export default {
  components: {
    ConfirmModal,
    Modal,
    Icon,
    CopyButton,
  },
  mixins: [
    RequestUtils,
  ],
  data() {
    return {
      apiKey: 'Loading...',
      apiUsage: 'Loading...',
      showRequestApiKeyModal: false,
      showApiUsageModal: false,
      showApiKey: false,
    };
  },
  computed: {
    applicationId() {
      return this.$store.state.app.get('id');
    },
    productPlan() {
      return this.$store.state.app.get('account').product_plan.name;
    },
    apiLimit() {
      const account = this.$store.state.app.get('account');

      return window.Knack.formatNumberWithCommas(account.plan_limits.api_limit);
    },
    isAppHipaa() {
      return get(this.app, 'account.settings.hipaa.enabled') === true;
    },
  },
  mounted() {
    this.getApiKey();
    this.setApiUsage();
  },
  methods: {
    getApiKey() {
      this.commitRequest({
        request: () => window.Knack.Api.getApiKey(),
        onSuccess: (apiKey) => this.apiKey = apiKey,
      });
    },
    setApiKey() {
      this.commitRequest({
        request: () => window.Knack.Api.getNewApiKey(),
        onSuccess: (newApiKey) => {
          this.apiKey = newApiKey;

          this.showRequestApiKeyModal = false;
        },
      });
    },
    async setApiUsage() {
      this.apiUsage = await window.Knack.Api.getApiUsage();
    },
    handleToggleApiKeyVisibility() {
      this.showApiKey = !this.showApiKey;
    },
  },
};
</script>

<style lang="scss">
#api-loaded {
  p {
    margin-bottom: 1em;
  }

  p.buttons {
    display: flex;
  }

  .button {
    margin-right: .5em;
  }
}

#api-new-key-confirm,
#api-usage {
  padding: 1em;
}

#api-usage-summary {
  max-height: 140px;
  overflow-y: scroll;
}

.v-popover > span {
  &:focus {
    outline: none;
  }
}

.tooltip.request-api-key-popover,
.tooltip.view-api-usage-summary-popover {
  border: 1px solid #e6e8ec;
  border-radius: .25em;

  .popover-inner {
    background-color: #fefefe;
    border-radius: .125em;
    color: #000;
  }

  .tooltip-arrow {
    border-color: #e6e8ec;
  }
}

/* Table Styles */
.kn-table-wrapper > table {
  width: 100%;

  td,
  th {
    border: 1px solid transparent;
    border-bottom-color: #dbdbdb;
    padding: 6px 8px;
    vertical-align: top;
    white-space: nowrap;
    text-align: left;
    position: relative;
  }
  thead {
    transition: opacity 0.1s ease-in;
    tr:hover {
      background-color: inherit;
    }
    td,
    th {
      border-bottom-width: 2px;

      .kn-item {
        position: absolute;
        top: -1px; right: -1px; bottom: -1px; left: -1px;
        border: 1px solid transparent;
        border-bottom-color: #dbdbdb;
        padding: 6px 8px;
        cursor: move;
        margin: 0;
        border-radius: 0;
      }
    }
  }
  tbody {
    tr {
      &:last-child {
        td,
        th {
          border-bottom-width: 0;
        }
      }
    }
  }
  tfoot {
    td,
    th {
      border-width: 2px 0 0;
    }
  }
  // Modifiers
  &.is-bordered {
    td,
    th,
    .kn-item {
      border-width: 1px;
      border-color: #dbdbdb;
    }
    th {
      background-color: #ecedef;
    }
    tr:last-child {
      td,
      th,
      .kn-item {
        border-bottom-width: 1px;
      }
    }
  }
  &.small-spacing {
    font-size: .94em;
    td,
    .kn-item {
      padding: 3px 6px;
      // Modifiers
      &.is-icon {
        padding: 2px;
        &.is-link {
          padding: 0;
          & > a {
            padding: 2px;
          }
        }
      }
      &.is-link {
        padding: 0;
        & > a {
          padding: 5px 10px;
        }
      }
    }
  }
  &.large-spacing {
    font-size: 1em;
    td,
    .kn-item {
      padding: 10px 15px;
      // Modifiers
      &.is-icon {
        padding: 2px;
        &.is-link {
          padding: 0;
          & > a {
            padding: 2px;
          }
        }
      }
      &.is-link {
        padding: 0;
        & > a {
          padding: 5px 10px;
        }
      }
    }
  }

  &.is-striped {
    tbody tr:nth-child(even) {
      background-color: #fafafa;
    }
  }

  &.is-unbordered {
    td,
    .kn-item {
      border-color: transparent;
    }
    th {
      background-color: rgba(0, 0, 0, 0.05); // + Added to make details view match old styles
    }
    tr:last-child {
      td,
      .kn-item {
        border-bottom-width: 0px;
      }
    }
  }

  &.cell-editable {
    td.cell-edit {
      cursor: pointer;

      &:hover {
        background-color: rgba(0, 0, 0, 0.05);
      }
    }
  }

  &.expand-records {
    td.kn-pivot-calc {
      cursor: pointer;

      &:hover {
        background-color: rgba(0, 0, 0, 0.05);
      }
    }
  }

  .kn-table-group {
    background-color: rgba(0, 0, 0, 0.2) !important;
    td {
      font-weight: bold;
    }
  }

  .kn-table-table {
    // Modifiers
    &.can-overflow-x {
      overflow-x: auto;
    }
  }
}

.kn-table-wrapper > table {
  .add-column {
    position: absolute;
    border: 0;
    top: 0;
    right: -40px;
    padding: 5px;
    background-color: rgba(119, 27, 102, .95);
    border-radius: 4px;
    color: #fff;
    height: 25px;
    width: 25px;
    opacity: 0;
  }

  > th:hover > .item-links, > th.router-link-active > .item-links, .kn-table > table:hover .add-column, .kn-table.is-active > table th > a {
    opacity: 1;
    visibility: visible;
  }

  .item-wrapper:hover .kn-item, .item-wrapper.router-link-active .kn-item {
    border: 1px solid rgba(42, 178, 123, 1);
    border-bottom-width: 2px;
    z-index: 3;
  }

  &.is-bordered {
      .item-wrapper:hover .kn-item, th.router-link-active .kn-item {
      border-bottom-width: 1px;
    }
  }
}

.kn-table.is-active th > .item-links {
  background-color: rgba(42, 178, 123, .85);
}

.kn-table {
  .item-wrapper.is-dragging .kn-item {
    border: 1px solid #aaa;
  }
}
</style>
