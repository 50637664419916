<template>
  <div class="body-wrapper m-0 px-6 py-2">
    <Modal
      v-if="showScriptProtectionConfirmationModal"
      title="Confirmation Required"
      @close="showScriptProtectionConfirmationModal = false"
    >
      <div v-if="!scriptProtectionIsEnabled">
        <p>
          By enabling <a
            class="text-default underline"
            href="https://learn.knack.com/article/tt3nyix86r-live-app-security-settings"
            target="_blank"
          >this setting</a>, any updates to records and views that currently contain code not on the <a
            class="text-default underline"
            href="https://learn.knack.com/article/0niud439eg-script-attack-protection-whitelisted-custom-code-elements-and-attributes"
            target="_blank"
          >whitelist</a> will be sanitized and removed.
        </p>
        <p><strong>Would you like to enable Script Attack Protection?</strong></p>
      </div>
      <div v-else>
        <p>
          By disabling <a
            class="text-default underline"
            href="https://learn.knack.com/article/tt3nyix86r-live-app-security-settings"
            target="_blank"
          >this setting</a>, this app will not be protected and could put your data at risk of <a
            class="text-default underline"
            href="https://www.owasp.org/index.php/Cross-site_Scripting_(XSS)"
            target="_blank"
          >input-based attacks</a>. You may choose to enable this setting in the future.
        </p>
        <p><strong>Are you sure you want to disable Script Attack Protection?</strong></p>
      </div>

      <div
        id="submit-confirm-script-protection"
        class="submit-buttons pt-0"
      >
        <a
          :class="[
            'button p-3 rounded-lg border-0 leading-none text-white text-base font-medium',
            scriptProtectionIsEnabled ? 'delete bg-red-600' : 'save bg-gradient-primary'
          ]"
          @click.prevent="onConfirmScriptProtection"
        >
          <span v-if="!scriptProtectionIsEnabled">
            I understand. Secure my data
          </span>
          <span v-else>
            I understand the risks. Opt me out
          </span>
        </a>
      </div>
    </Modal>

    <form class="max-w-xl">
      <div
        id="script-protection"
        class="mb-0"
      >
        <p class="text-sm text-default font-medium mb-2">
          Script Attack Protection is {{ scriptProtectionIsEnabled === true ? 'Enabled' : 'Disabled' }}
        </p>

        <p class="text-sm text-default font-normal mb-4">
          <a
            class="text-default underline"
            href="https://learn.knack.com/article/tt3nyix86r-live-app-security-settings"
            target="_blank"
          >This setting</a> prevents the storage and execution of code in record values and views that is not <a
            class="text-default underline"
            href="https://learn.knack.com/article/wrgxmjttk1-managing-your-apps#security"
            target="_blank"
          >whitelisted</a>. This helps keep your apps secure and prevents <a
            class="text-default underline"
            href="https://www.owasp.org/index.php/Cross-site_Scripting_(XSS)"
            target="_blank"
          >input-based hacks</a>. Custom Javascript and CSS stored in Knack will continue to be applied.
        </p>
        <div>
          <a
            id="enable-script-protection"
            class="button medium fuchsia-gray p-3 rounded-lg border border-solid border-default bg-white
              hover:bg-brand-50 hover:border-brand-600 leading-none text-emphasis text-base font-medium"
            @click.prevent="onClickScriptProtectionStatus"
          >
            {{ scriptProtectionIsEnabled === true ? 'Disable' : 'Enable' }}
          </a>
        </div>
      </div>

      <hr class="my-8">

      <IfPlan
        disallow-if-hipaa
        disallow-if-private
      >
        <div id="support-access-enable">
          <label
            for="support-access-enable"
            class="text-sm text-default font-medium mb-2"
          >Support Access</label>
          <div>
            <label class="mb-2">
              <input
                v-model="localApp.settings.support_access"
                class="my-0"
                type="checkbox"
                name="support_access_enable"
                :disabled="isCRM"
              >
              <span class="text-sm text-default font-medium">
                Grant Live App support access to the Knack team
              </span>
            </label>
            <p class="instructions checkbox mb-0 text-xs text-default font-normal not-italic">
              Read more about <a
                class="underline text-default"
                href="https://learn.knack.com/article/1ew3hb6ecm-working-with-support"
                target="_blank"
              >working with support</a>
            </p>
          </div>
        </div>
      </IfPlan>

      <IfPlan :level-is-minimum-of="2">
        <div
          id="ip-whitelist-enable"
          :class="{ 'mb-4': hasIpWhitelistEntries }"
        >
          <label
            for="ip_whitelist_enable"
            class="text-sm text-default font-medium"
          >Enable IP Blocking</label>
          <div>
            <label>
              <input
                v-model="hasIpWhitelistEntries"
                class="my-0"
                type="checkbox"
                name="ip_whitelist_enable"
              >
              <span class="text-sm text-default font-medium">
                Restrict which IP addresses can access your app
              </span>
            </label>
          </div>
        </div>
      </IfPlan>

      <div
        v-if="hasIpWhitelistEntries"
        id="ip-whitelist"
      >
        <label
          for="ip_whitelist"
          class="text-sm text-default font-medium mb-2"
        >Whitelisted IPs</label>
        <textarea
          v-model="localApp.settings.ip_whitelist"
          class="block mb-2"
          name="ip_whitelist"
        />
        <p class="instructions m-0 text-xs text-default font-normal not-italic">
          IPs can be added individually (192.168.5.22) or in ranges (one per line).
          When enabled only IP addresses listed here will have access to the app.
        </p>
      </div>

      <div v-if="localApp.settings.sql">
        <label class="text-sm text-default font-medium mb-2">Restrict API Responses</label>
        <div>
          <label class="mb-2">
            <input
              v-model="localApp.settings.secureViewApiPayloads"
              class="my-0"
              type="checkbox"
              name="secureViewApiPayloads"
            >
            <span class="text-sm text-default font-medium">
              Only include fields added to the view with any record responses
            </span>
          </label>
          <p class="instructions checkbox mb-0 text-xs text-default font-normal not-italic">
            This applies to record updates via <a
              href="https://docs.knack.com/docs/using-javascript-with-knack"
              class="underline text-default"
              target="_blank"
            >Live App's JavaScript events</a> and <a
              class="underline text-default"
              href="https://docs.knack.com/docs/view-based-requests"
              target="_blank"
            >view-based API requests</a>. This does not apply to record inserts and POST API requests,
            which always return all fields in response payloads.
          </p>
        </div>
      </div>

      <div id="https-redirect-enable">
        <label
          for="https_redirect_enable"
          class="text-sm text-default font-medium mb-2"
        >Secure Browser</label>
        <div>
          <label>
            <input
              v-model="localApp.settings.https_redirect"
              class="my-0"
              type="checkbox"
              name="https_redirect_enable"
            >
            <span class="text-sm text-default font-medium">
              Force the browser to use HTTPS to encrypt all traffic
            </span>
          </label>
        </div>
      </div>

      <IfPlan :level-is-minimum-of="2">
        <div id="record-history-enable">
          <label
            for="record_history_enable"
            class="text-sm text-default font-medium mb-2"
          >Record History</label>
          <div>
            <label class="mb-2"><input
              v-model="localApp.settings.isRecordHistoryEnabled"
              class="my-0"
              type="checkbox"
              name="record_history_enable"
            ><span class="text-sm text-default font-medium">Enable Record History</span></label>
            <p class="instructions checkbox mb-0 text-xs text-default font-normal not-italic">
              When enabled, a history of record changes is available. Retention periods for data history varies by plan.
            </p>
          </div>
        </div>
      </IfPlan>

      <div class="flex">
        <a
          class="button medium save bg-gradient-primary rounded-lg border-0 p-3 text-base leading-4 font-medium ml-auto"
          data-cy="save-settings"
          @click.prevent="onSave"
        >
          Save Settings
        </a>
      </div>
    </form>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import hasIn from 'lodash/hasIn';
import { isCRM } from '@/lib/env-helper';
import Modal from '@/components/ui/Modal';
import IfPlan from '@/components/util/IfPlan';
import RequestUtils from '@/components/util/RequestUtils';

export default {
  name: 'AppSecurity',
  components: {
    IfPlan,
    Modal,
  },
  mixins: [
    RequestUtils,
  ],
  data() {
    const localApp = JSON.parse(JSON.stringify(this.$store.getters.app.raw()));

    return {
      localApp,
      ipWhitelistStatus: localApp.settings.ip_whitelist.length > 0,
      showScriptProtectionConfirmationModal: false,
    };
  },
  computed: {
    ...mapGetters([
      'app',
    ]),
    isCRM: {
      get() {
        return isCRM();
      },
    },
    hasIpWhitelistEntries: {
      get() {
        return this.ipWhitelistStatus;
      },
      set(newValue) {
        this.ipWhitelistStatus = newValue;
      },
    },
    scriptProtectionIsEnabled: {
      get() {
        return this.localApp.settings.scriptProtectionEnabled;
      },
      set(newValue) {
        this.localApp.settings.scriptProtectionEnabled = newValue;
      },
    },
  },
  watch: {
    hasIpWhitelistEntries(newValue) {
      if (!newValue) {
        this.localApp.settings.ip_whitelist = '';
      }
    },
  },
  created() {
    this.notifyErrors = true;

    if (!hasIn(this.localApp, 'settings.secureViewApiPayloads')) {
      this.localApp.settings.secureViewApiPayloads = true;
    }

    if (!hasIn(this.localApp, 'settings.isRecordHistoryEnabled')) {
      this.localApp.settings.isRecordHistoryEnabled = true;
    }

    if (!hasIn(this.localApp, 'settings.scriptProtectionEnabled')) {
      this.localApp.settings.scriptProtectionEnabled = false;
    }
  },
  methods: {
    onSave(event) {
      this.commitRequest({
        validate: () => this.app.validate(this.localApp),
        request: () => this.app.update({
          settings: this.localApp.settings,
        }),
      });
    },
    onClickScriptProtectionStatus(event) {
      this.showScriptProtectionConfirmationModal = true;
    },
    onConfirmScriptProtection(event) {
      this.scriptProtectionIsEnabled = !this.scriptProtectionIsEnabled;

      this.commitRequest({
        validate: () => this.app.validate(this.localApp),
        request: () => this.app.update({
          settings: {
            scriptProtectionEnabled: this.scriptProtectionIsEnabled,
          },
        }),
      });

      this.showScriptProtectionConfirmationModal = false;
    },
  },
};
</script>

<style lang="scss">
#settings-body {
  form {
    hr {
      border: solid #ddd;
      border-width: 1px 0 0;
      clear: both;
      margin: 12px 0 18px;
      height: 0;
    }
  }
}
</style>
