<template>
  <div class="body-wrapper">
    <ObjectsEmpty v-if="!firstObject" />
  </div>
</template>

<script>
import Icon from '@/components/ui/Icon';
import EmptyStateGeneric from '@/components/ui/EmptyStateGeneric';
import ObjectsEmpty from '@/components/objects/ObjectsEmpty';

export default {
  components: {
    Icon,
    ObjectsEmpty,
    EmptyStateGeneric,
  },
  props: {
    layout: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {};
  },
  computed: {
    firstObject() {
      return this.$store.getters.standardObjects[0];
    },
  },
  mounted() {
    if (this.firstObject && this.$router.currentRoute.value.path.replace(/\/+$/, '') === '/records') {
      this.$router.replace(`/records/objects/${this.firstObject.key}`);
    }
  },
};
</script>
