<template>
  <div>
    <LinkedPagePicker
      v-if="isAddingLinkedPage"
      :object-key="view.source.object"
      @close="addLinkedPageEnd"
      @onSelectLinkedPage="onSelectLinkedPage"
    />

    <FieldAddSettings
      v-if="showFieldAdd"
      :close-on-event="true"
      @close="showFieldAdd = false"
      @save="onAddNewField"
    />

    <div
      id="view-add-items"
      :class="{ 'pb-10': isToolboxSaveRendered }"
    >
      <slot />

      <div class="buttonFilter margin-bottom rounded-lg p-1 border border-solid border-default bg-white w-fit mt-4 mb-0.5">
        <BuilderButton
          v-for="{ slug } in availableGroups"
          :key="slug"
          class="buttonFilter_button text-base font-semibold tracking-[0.32px] rounded px-2 py-1 border-0 text-default inline-flex"
          :class="{
            'is-active bg-brand-100 text-emphasis': isButtonFilterActive(slug),
            'bg-transparent hover:bg-brand-50': !isButtonFilterActive(slug),
          }"
          theme="info"
          :theme-override="{text: buttonTextColor}"
          :size="leftPanelBreakdownClass"
          :data-cy="slug"
          :data-feature="`${slug}_button`"
          data-feature-x-offset="-24"
          data-feature-y-offset="-32"
          @click.prevent="setGroupFilter(slug)"
        >
          {{ capitalize(slug) }}
        </BuilderButton>
      </div>

      <!-- FIELDS & CONNECTED FIELDS LISTS -->
      <div
        v-for="(list, index) in groupLists"
        :key="index"
      >
        <div class="items-wrapper">
          <Toggle
            :arrow-is-first="true"
            class="mt-4"
          >
            <template #title>
              <div class="title-wrapper">
                <div class="title-label-wrapper">
                  <span class="truncate">
                    {{ list.title }}
                  </span>
                  <Popover
                    placement="bottom-start"
                    :popover-classes="['ml-2']"
                  >
                    <template #trigger>
                      <button
                        v-tippy
                        content="Add & create fields..."
                        type="button"
                        class="buttonSquare-outline blue border-0 bg-transparent rounded hover:bg-subtle p-0"
                        @click.stop=""
                      >
                        <Icon
                          class="h-4 w-4 text-default"
                          type="ellipsis-horizontal"
                        />
                      </button>
                    </template>

                    <template #content>
                      <div class="links p-0">
                        <button
                          v-close-popper
                          class="link rounded-lg hover:bg-brand-50 text-base text-default hover:text-emphasis p-2"
                          @click="onClickAddAllFields(list.object.key, list.object.connectionKey)"
                        >
                          Add all <strong>{{ list.object.inflections.singular }}</strong> fields
                        </button>
                        <button
                          v-close-popper
                          class="link rounded-lg hover:bg-brand-50 text-base text-default hover:text-emphasis p-2"
                          @click="onClickCreateNewField(list.object.key)"
                        >
                          Create a new <strong>{{ list.object.inflections.singular }}</strong> field
                        </button>
                      </div>
                    </template>
                  </Popover>
                </div>
                <div
                  v-if="list.slug === `connections`"
                  v-tippy="{ allowHTML: true }"
                  :content="`Add fields for ${list.object.objectName} records <br />connected via ${list.object.connectionName.object} ${list.object.connectionName.field}`"
                  class="connection text-default"
                >
                  <Icon
                    type="database-lookup"
                    class="'h-4 w-4 text-subtle'"
                    style="margin-right: 2px;"
                  /> {{ list.object.connectionName.object }}<Icon
                    class="h-4 w-4 text-subtle"
                    type="chevron-single"
                  />{{ list.object.connectionName.field }}
                </div>
              </div>
            </template>

            <template #content>
              <div class="items-list">
                <ExpandableList
                  :ref="`${index}-expandableList`"
                  v-model:expanded="list.expanded"
                  :items="list.items"
                  :show-expand-trigger="groupLists.length > 1"
                >
                  <template #visible="visible">
                    <div
                      v-for="field in visible.items"
                      :key="field.key"
                      v-tippy
                      :content="fieldIsDisabled(field) ? fieldDisabledMessage(field) : ''"
                      :data-field-key="field.key"
                      :data-connection-key="getDataConnectionKey(list.slug, list.object)"
                      :draggable="isFieldDraggable(list.slug, field)"
                      class="view-add-item margin-bottom-xs"
                      :class="{'is-disabled': list.slug === `fields` && fieldIsDisabled(field)}"
                      @click="onClickItem($event, fieldIsDisabled(field))"
                      @dragstart="onDragStart"
                      @dragend="onDragEnd"
                    >
                      <a
                        class="bg-transparent p-2 rounded-lg text-default border border-solid border-default"
                        :class="{ 'hover:text-emphasis hover:border-brand-600 hover:bg-brand-50 group': !(list.slug === `fields` && fieldIsDisabled(field)) }"
                      >
                        <TypeIcon
                          class="k-itemIcon"
                          icon-classes="h-4 w-4 text-subtle group-hover:text-brand-400"
                          :field="field"
                        />
                        <span class="truncate">{{ field.name }}</span>
                      </a>
                    </div>
                  </template>
                  <template #expandable="expandable">
                    <div
                      v-for="field in expandable.items"
                      :key="field.key"
                      v-tippy
                      :content="fieldIsDisabled(field) ? fieldDisabledMessage(field) : field?.attributes?.type"
                      :data-field-key="field.key"
                      :data-connection-key="getDataConnectionKey(list.slug, list.object)"
                      :draggable="isFieldDraggable(list.slug, field)"
                      class="view-add-item margin-bottom-xs"
                      :class="{'is-disabled': list.slug === `fields` && fieldIsDisabled(field)}"
                      @click="onClickItem($event, fieldIsDisabled(field))"
                      @dragstart="onDragStart"
                      @dragend="onDragEnd"
                    >
                      <a
                        class="bg-transparent p-2 rounded-lg text-default border border-solid border-default"
                        :class="{ 'hover:text-emphasis hover:border-brand-600 hover:bg-brand-50 group': !(list.slug === `fields` && fieldIsDisabled(field)) }"
                      >
                        <TypeIcon
                          class="k-itemIcon"
                          icon-classes="h-4 w-4 text-subtle group-hover:text-brand-400"
                          :field="field"
                        />
                        <span>{{ field.name }}</span>
                      </a>
                    </div>
                  </template>
                </ExpandableList>
              </div>
            </template>
          </Toggle>
        </div>
      </div>

      <!-- SEARCH OPTIONS -->
      <template v-if="shouldShowGroup(`search`)">
        <div class="items-wrapper">
          <Toggle
            :arrow-is-first="true"
            class="mt-4"
          >
            <template #title>
              <div>
                <span>Search Elements</span>
              </div>
            </template>
            <template #content>
              <div class="items-list add-items-list">
                <div
                  v-for="item in searchItems"
                  :key="item.type"
                  v-tippy
                  :content="keywordIsDisabled ? 'A keyword search already exists' : ''"
                  :data-search="item.type"
                  :draggable="!keywordIsDisabled"
                  class="view-add-item margin-bottom-xs"
                  :class="{'is-disabled': keywordIsDisabled}"
                  @click="onClickItem($event, keywordIsDisabled)"
                  @dragstart="onDragStart"
                  @dragend="onDragEnd"
                >
                  <a
                    class="bg-transparent p-2 rounded-lg text-default border border-solid border-default"
                    :class="{ 'hover:text-emphasis hover:border-brand-600 hover:bg-brand-50 group': !keywordIsDisabled }"
                  >
                    <Icon
                      class="k-itemIcon"
                      icon-classes="h-4 w-4 text-subtle group-hover:text-brand-400"
                      :type="item.icon || item.type"
                    />
                    <span>{{ item.label }}</span>
                  </a>
                </div>
              </div>
            </template>
          </Toggle>
        </div>
      </template>

      <!-- LINK OPTIONS -->
      <template v-if="shouldShowGroup(`actions`)">
        <div class="items-wrapper">
          <Toggle
            :arrow-is-first="true"
            class="mt-4"
          >
            <template #title>
              <div>
                <span>Action Links</span>
              </div>
            </template>
            <template #content>
              <div class="items-list add-items-list">
                <div
                  v-for="item in linkItems"
                  :key="item.type"
                  :data-link="item.type"
                  :data-cy="item.type"
                  draggable="true"
                  class="margin-bottom-xs"
                  @dragstart="onDragStart"
                  @dragend="onDragEnd"
                  @click="onClickItem"
                >
                  <a
                    :data-feature="`${item.type}_record_action`"
                    data-feature-x-offset="-6"
                    data-feature-y-offset="-6"
                    class="bg-transparent p-2 rounded-lg text-default border border-solid border-default hover:text-emphasis hover:border-brand-600 hover:bg-brand-50 group"
                  >
                    <Icon
                      class="k-itemIcon"
                      icon-classes="h-4 w-4 text-subtle group-hover:text-brand-400"
                      :type="item.icon"
                    />
                    <span>{{ item.label }}</span>
                  </a>
                </div>
              </div>
            </template>
          </Toggle>
        </div>
      </template>

      <!-- STATIC OPTIONS -->
      <template v-if="shouldShowGroup(`static`)">
        <div class="items-wrapper">
          <Toggle class="mt-4">
            <template #title>
              <div>
                <span>Static Elements</span>
              </div>
            </template>
            <template #content>
              <div class="items-list add-items-list">
                <div
                  v-for="item in staticItems"
                  :key="item.type"
                  :data-static="item.type"
                  draggable="true"
                  class="margin-bottom-xs"
                  @dragstart="onDragStart"
                  @dragend="onDragEnd"
                  @click="onClickItem"
                >
                  <a class="bg-transparent p-2 rounded-lg text-default border border-solid border-default hover:text-emphasis hover:border-brand-600 hover:bg-brand-50 group">
                    <Icon
                      class="k-itemIcon"
                      icon-classes="h-4 w-4 text-subtle group-hover:text-brand-400"
                      :type="item.icon || item.type"
                    />
                    <span>{{ item.label }}</span>
                  </a>
                </div>
              </div>
            </template>
          </Toggle>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import capitalize from 'lodash/capitalize';
import {
  mapMutations,
  mapGetters,
} from 'vuex';

import BuilderButton from '@/components/ui/BuilderButton';
import Icon from '@/components/ui/Icon';
import LinkedPagePicker from '@/components/views/shared/LinkedPagePicker';
import Popover from '@/components/ui/Popover';
import ExpandableList from '@/components/ui/lists/ExpandableList';
import FieldAddSettings from '@/components/fields/FieldAddSettings';
import SchemaUtils from '@/components/util/SchemaUtils';
import Toggle from '@/components/ui/Toggle';
import TypeIcon from '@/components/fields/TypeIcon';
import ViewUtils from '@/components/views/ViewUtils';
import { eventBus } from '@/store/bus';
import { grayTextHover } from '@/styles/scss/helpers/_variables.scss';
import { logEvent } from '@/lib/segment-helper';
import { trackEvent } from '@/lib/rudderstack-helper';

export default {
  name: 'AddItems',
  components: {
    BuilderButton,
    FieldAddSettings,
    Icon,
    LinkedPagePicker,
    Popover,
    Toggle,
    TypeIcon,
    ExpandableList,
  },
  mixins: [
    ViewUtils,
    SchemaUtils,
  ],
  props: {
    viewType: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      thresholdToShowToggleForMore: 7,
      manyRecords: [
        'table',
        'list',
        'map',
        'search',
        'calendar',
      ],
      singleRecords: [
        'form',
        'details',
      ],
      groupFilter: [],
      buttonTextColor: grayTextHover,
      showFieldAdd: false,
    };
  },
  computed: {
    ...mapGetters('ui', [
      'leftPanelBreakdownClass',
    ]),
    isAddingLinkedPage() {
      return this.$store.getters.isAddingLinkedPage;
    },
    localViewType() {
      // search passes in the viewType prop since it could be list or table
      const localViewType = this.viewType || this.view.type;

      // most views share details
      const detailViews = [
        'details',
        'list',
        'map',
        'calendar',
        'checkout',
        'charge',
        'customer',
      ];

      if (detailViews.includes(localViewType)) {
        return 'details';
      }

      // table, form, and others will pass down to here
      return localViewType;
    },
    // Identify the collection for views with multiple field collections
    // e.g. search views can have inputs and results for both table and list
    fieldCollectionType() {
      const viewTypeMap = {
        details: 'details',
        table: 'columns',
        form: 'inputs',
        list: 'list',
      };

      return viewTypeMap[this.localViewType];
    },
    groupLists() {
      return this.fieldsLists.filter((list) => this.shouldShowGroup(list.slug));
    },
    availableGroups() {
      const groups = [
        {
          slug: 'fields',
          condition: true,
        },
        {
          slug: 'search',
          condition: this.view.type === 'search' && this.localViewType === 'form',
        },
        {
          slug: 'connections',
          condition: this.view.type !== 'form' && this.connectedObjects.length > 0,
        },
        {
          slug: 'actions',
          condition: this.localViewType !== 'form',
        },
        {
          slug: 'static',
          condition: this.localViewType !== 'table',
        },
      ];

      return groups.filter((group) => group.condition);
    },
    connectedObjects() {
      const connectedObjects = [];

      if (!this.object || !this.object.conns) {
        return connectedObjects;
      }

      this.object.conns.forEach((conn) => {
        const connectedObject = this.$store.getters.getObject(conn.object);
        let connOwner;

        if (conn.relationship_type === 'foreign') {
          connOwner = connectedObject.get('name');
        } else {
          connOwner = this.object.name;
        }

        connectedObjects.push({
          objectName: connectedObject.inflections.singular,
          connectionName: {
            object: connOwner,
            field: conn.name,
          },
          key: conn.object,
          inflections: connectedObject.inflections,
          connectionKey: conn.key,
          fields: this.parseRawFieldsIntoAvailableFields(connectedObject.fields),
        });
      });

      return connectedObjects;
    },
    isSingleRecord() {
      return (this.singleRecords.indexOf(this.localViewType) > -1);
    },
    isManyRecords() {
      return (this.manyRecords.indexOf(this.localViewType) > -1);
    },
    isDetails() {
      return this.localViewType === 'details';
    },
    availableFields() {
      return this.parseRawFieldsIntoAvailableFields(this.object?.fields);
    },
    objectName() {
      return this.object?.inflections?.singular || '';
    },
    linkItems() {
      const linkItems = [
        {
          type: 'edit', icon: 'edit', label: 'Edit record',
        },
        {
          type: 'details', icon: 'details', label: 'View record details',
        },
        {
          type: 'delete', icon: 'delete', label: 'Delete record',
        },
      ];

      if (this.hasDetailPagesToLinkTo) {
        linkItems.push({
          type: 'link', icon: 'link', label: 'Link to another page',
        });
      }

      linkItems.push({
        type: 'action_link', icon: 'action-alt', label: 'Trigger an action',
      });

      return linkItems;
    },
    staticItems() {
      return [
        {
          type: 'copy', icon: 'short-text', label: 'Title/Copy',
        },
        {
          type: 'divider', icon: 'divider', label: 'Divider',
        },
      ];
    },
    searchItems() {
      return [
        {
          type: 'keyword', icon: 'search', label: 'Keyword Search',
        },
      ];
    },
    hasDetailPagesToLinkTo() {
      return this.$store.getters.getDetailPagesByObject(this.viewRaw.source.object).length;
    },
    isColumnItem() {
      if (this.localViewType === 'table') {
        return true;
      }

      // update to reflect results type
      if (this.localViewType === 'search' && this.viewRaw.results_type === 'table') {
        return true;
      }

      return false;
    },
    keywordIsDisabled() {
      return this.view.searchViewHasKeyword();
    },
    viewActiveInputs() {
      return this.$store.getters.activeView.getInputs();
    },
    fieldsLists() {
      const lists = [];

      lists.push(this.generateListObject(this.object, 'fields'));

      if (this.connectedObjects.length > 0) {
        this.connectedObjects.forEach((object) => {
          const list = this.generateListObject(object, 'connections');

          // if just one connected list we can auto-expand it
          list.expanded = this.connectedObjects.length === 1;

          lists.push(list);
        });
      }

      return lists;
    },
    isToolboxSaveRendered() {
      const { getters } = this.$store;

      return getters.viewHasActiveUpdates || getters.pageHasActiveUpdates;
    },
  },
  beforeUnmount() {
    // remove event so dragging a new item after creation doesn't trigger this
    eventBus.$off(`addViewItemEnd.${this.view?.key}.${this.localViewType}`);
  },
  mounted() {
    this.groupFilter.push(this.availableGroups[0].slug);
  },
  methods: {
    ...mapMutations([
      'addLinkedPageStart',
      'addLinkedPageEnd',
    ]),
    capitalize,
    onClickAddAllFields(objectKey, connectionKey) {
      // set active object
      const object = this.$store.getters.getObject(objectKey);

      log('onClickAddAllFields()', this.fieldCollectionType);

      // fieldCollectionType maps to which collection to get fields from. E.g. search can be inputs, list, or table
      const existingFields = this.$store.getters.activeView.getFields(this.fieldCollectionType);

      log('existingFields', existingFields);

      for (const field of object.fields) {
        // Ensure the active view doesn't already have this field
        if (existingFields.find((existingField) => existingField.key === field.key)) {
          continue;
        }

        // Ensure we can add it to this view
        if (!this.fieldIsEligible(field)) {
          continue;
        }

        const item = this.formatItem(this.setFieldItem(field), field, field.key, connectionKey);

        this.emitAddItem({
          item,
        });
      }
    },
    onClickCreateNewField(objectKey) {
      // set active object
      const object = this.$store.getters.getObject(objectKey);

      this.$store.commit('activeObject', object);

      // show modal
      this.showFieldAdd = true;
    },
    onAddNewField() {
      this.showFieldAdd = false;
    },
    isButtonFilterActive(slug) {
      const isSlugAll = slug === 'all';

      return isSlugAll ? this.groupFilter.length === 0 : this.groupFilter.includes(slug);
    },
    isFieldDraggable(slug, field) {
      const isConnections = slug === 'connections';

      return isConnections ? true : !this.fieldIsDisabled(field);
    },
    getDataConnectionKey(slug, object) {
      const isConnections = slug === 'connections';

      return isConnections ? object.connectionKey : null;
    },
    generateListObject(object, slug) {
      const list = {
        object,
        slug,
        expanded: false,
      };

      switch (slug) {
        case 'fields': {
          list.title = `${this.objectName} Fields`;
          list.items = this.availableFields;
          list.expanded = true;
          break;
        }

        default: {
          list.title = `${object.objectName} Fields`;
          list.items = object.fields;
          break;
        }
      }

      return list;
    },
    shouldShowGroup(slug) {
      let showGroup = false;
      const isGroupAvailable = this.availableGroups.filter((group) => group.slug === slug).length > 0;

      // if group is not available or no items are in list stop evaluating & don't show list
      if (!isGroupAvailable) {
        return showGroup;
      }

      // does slug match filter
      if (this.groupFilter.includes(slug)) {
        showGroup = true;
      }

      return showGroup;
    },
    setGroupFilter(slug) {
      this.$store.commit(
        'checklists/setHighlightedFeatures',
        this.$store.getters['checklists/highlightedFeatures'].filter((f) => f !== `${slug}_button`),
      );

      const filter = [
        slug,
      ];

      this.groupFilter = filter;
    },
    parseRawFieldsIntoAvailableFields(rawFields) {
      if (_.isEmpty(rawFields)) {
        return [];
      }

      let fields = [];

      rawFields.forEach((field) => {
        if (!this.fieldIsEligible(field)) {
          return;
        }

        fields.push(field);

        // image thumbs
        if (this.viewType !== 'form' && field.isTypeImage()) {
          fields = fields.concat(field.getThumbsAsFieldOptions());
        }
      });

      return fields;
    },
    fieldIsDisabled(field) {
      // Fields can only be disabled in forms. Other views don't care if you add a field more than once.
      if (this.localViewType !== 'form') {
        return false;
      }

      if (this.formFieldIsAlreadyAdded(field)) {
        return true;
      }

      if (this.formFieldHasConditionalRules(field)) {
        return true;
      }

      return false;
    },
    fieldDisabledMessage(field) {
      let message;
      if (this.formFieldHasConditionalRules(field)) {
        message = 'This field can\'t be added to the form because its value is set by conditional rules.';
      } else {
        message = 'This field can only be added once and already exists on the form.';
      }

      return message;
    },
    formFieldIsAlreadyAdded(field) {
      return this.viewActiveInputs.find((input) => (input.field && input.field.key === field.key));
    },
    formFieldHasConditionalRules(field) {
      // Can't add conditional fields to forms
      if (this.view.type === 'form' && field.hasConditionalRules()) {
        return true;
      }

      return false;
    },
    fieldIsEligible(field) {
      // Can only show password in forms
      if (field.type === window.Knack.config.PASSWORD && this.view.type !== 'form') {
        return false;
      }

      if (this.view.type === 'form') {
        // Can show regular equations for online calculator type functionality
        if (field.type === window.Knack.config.EQUATION) {
          return true;
        }

        // Can't show auto increments in forms
        if (field.type === window.Knack.config.AUTO_INCREMENT) {
          return false;
        }

        // Can't show any formulas
        if (window.Knack.config.fields[field.type].group === 'formula') {
          return false;
        }
      }

      return true;
    },
    onDragStart(event) {
      event.stopPropagation();

      // views check this to ensure eligible drop zones
      this.$store.commit('dragStartNewViewItem', {
        viewKey: this.view.key,
        viewArea: this.localViewType,
      });

      const item = this.setItem(event);

      event.dataTransfer.effectAllowed = 'move';
      event.dataTransfer.setData('new', JSON.stringify(item));

      event.target.classList.add('is-dragging');
      event.target.style.cursor = 'move';

      this.listenForAddItemEnd();

      eventBus.$emit('dragStartItemNew', event);
    },
    onDragEnd(event) {
      // remove this from store
      this.$store.commit('dragEndNewViewItem');

      event.target.classList.remove('is-dragging');
      const dropTarget = document.querySelector('.drop-target.over');

      if (dropTarget) {
        dropTarget.classList.remove('over');
      }

      return eventBus.$emit('dragEndItemNew', event);
    },
    onSelectLinkedPage(linkedPage) {
      // create new item using defaults and info from selected page
      const item = {
        type: this.localViewType === 'table' ? 'link' : 'scene_link',
        remote: true,
        scene: linkedPage.slug,
        link_text: linkedPage.name,
      };

      // format for column items
      if (this.isColumnItem) {
        item.header = linkedPage.name;
      }

      // pass along an event if available (e.g. from a drag/drop)
      const event = this.$store.getters.addLinkedPageEvent;

      this.emitAddItem({
        item, event,
      });

      // end state tracking
      this.addLinkedPageEnd();
    },
    setItem(event) {
      const $el = event.currentTarget;
      const link = $el.getAttribute('data-link');
      const staticType = $el.getAttribute('data-static');
      const searchType = $el.getAttribute('data-search');

      // handle special types first
      if (link) {
        return this.formatItem(this.setLinkItem(link));
      }

      if (staticType) {
        return this.formatItem(this.setStaticItem(staticType));
      }

      if (searchType) {
        return this.setSearchItem(searchType);
      }

      // default is field type
      const fieldKey = $el.getAttribute('data-field-key');

      const connectionKey = $el.getAttribute('data-connection-key');

      const field = this.getField(fieldKey);

      return this.formatItem(this.setFieldItem(field), field, fieldKey, connectionKey);
    },
    formatItem(item, field, fieldKey, connectionKey) {
      // Needed by thumbnails for adding : in keys (e.g. field_1:thumb_2)
      if (fieldKey && fieldKey.indexOf(':') > -1) {
        const thumbKey = fieldKey.split(':')[1];

        if (item.field) {
          item.field.key = fieldKey;
          item.field.thumb_key = thumbKey;
        } else {
          item.key = fieldKey;
        }

        item.label += ` - ${field.getThumbNameFromKey(thumbKey)}`;
      }

      // format for column items
      if (this.isColumnItem) {
        item.header = item.label;
        delete item.label;
      }

      if (this.localViewType === 'details' && item.label) {
        item.name = item.label;
        delete item.label;
      }

      // connection fields
      if (connectionKey) {
        item.connection = {
          key: connectionKey,
        };
      }

      return item;
    },
    setSearchItem(searchType) {
      return {
        field: 'keyword_search',
        name: 'Keyword Search',
      };
    },
    setStaticItem(staticType) {
      switch (staticType) {
        case 'copy':
          if (this.isDetails) {
            return {
              value: 'Title',
              copy: 'Copy',
              type: 'special_title',
            };
          }

          if (this.view.type === 'search') {
            return {
              name: 'Title',
              copy: 'Copy',
              field: 'section_break',
            };
          }

          return {
            label: 'Title',
            copy: 'Copy',
            type: 'section_break',
          };

        case 'image':
          return {
            type: 'image',
            image: null,
          };

        case 'divider':
          return {
            type: 'divider',
          };
      }
    },
    setLinkItem(link) {
      // details views
      if (this.isDetails) {
        switch (link) {
          case 'delete':
            return {
              type: 'delete',
              link_text: 'delete',
            };

          case 'edit':
            return this.setLinkItemPage(link);

          case 'details':
            return this.setLinkItemPage(link);

          case 'link':
            const firstLinkedPage = this.$store.getters.getDetailPagesByObject(this.object.key)[0];

            return {
              link_text: firstLinkedPage.name,
              type: 'scene_link',
              scene: firstLinkedPage.slug,
              remote: true,
            };

          case 'action_link':

            return {
              label: 'Trigger an action',
              type: 'action_link',
              link_text: 'action',
              action_rules: [
                {
                  criteria: [],
                  link_text: 'action',
                  record_rules: [],
                  submit_rules: [
                    {
                      action: 'message',
                      message: 'Action successfully completed.',
                      reload_show: false,
                    },
                  ],
                },
              ],
            };
        }
      }

      // non-detail views
      switch (link) {
        case 'delete':
          return {
            label: 'Delete',
            type: 'delete',
            link_text: 'delete',
          };

        case 'edit':
          return this.setLinkItemPage(link);

        case 'details':
          return this.setLinkItemPage(link);

        case 'link':
          const firstLinkedPage = this.$store.getters.getDetailPagesByObject(this.object.key)[0];

          return {
            label: firstLinkedPage.name,
            link_text: firstLinkedPage.name,
            type: 'link',
            scene: firstLinkedPage.slug,
            remote: true,
            sortable: false,
          };

        case 'action_link':

          return {
            label: 'Trigger an action',
            type: 'action_link',
            link_text: 'action',
            action_rules: [
              {
                criteria: [],
                link_text: 'action',
                record_rules: [],
                submit_rules: [
                  {
                    action: 'message',
                    message: 'Action successfully completed.',
                    reload_show: false,
                  },
                ],
              },
            ],
          };
      }
    },
    setFieldItem(field) {
      if (this.view.type === 'search' && this.viewType === 'form') {
        return {
          field: field.key,
          name: field.name,
          operator: 'is any',
        };
      }

      if (this.isDetails) {
        return {
          key: field.key,
          name: field.name,
          label: field.name,
        };
      }

      const item = {
        field: {
          key: field.key,
        },
        id: field.key,
        label: field.name,
        type: 'field',
      };

      // non-form items
      if (this.localViewType !== 'form') {
        return item;
      }

      // forms have types = field type
      item.type = field.type;

      // form items
      if (field.type === 'connection') {
        item.source = {
          filters: [],
        };
      }

      return item;
    },
    setLinkItemPage(link) {
      let viewType = 'none';
      const type = (this.isDetails) ? 'scene_link' : 'link'; // Puke. Tables use `link` for new child pages. Would love to standarize this.
      let label = '';
      let linkText = '';
      let page = {};

      if (link === 'edit') {
        label = `Edit ${this.object.inflections.singular}`;
        viewType = 'form';
        linkText = 'edit';
      } else if (link === 'details') {
        label = `View ${this.object.inflections.singular} Details`;
        viewType = 'details';
        linkText = 'view';
      }

      page = {
        name: label,
        object: this.object.key,
        parent: this.$store.getters.activePage.slug,
      };
      const views = [
        {
          name: label,
          type: viewType,
        },
      ];

      page = this.buildPageSchema(page, views);
      const item = {
        label,
        link_text: linkText,
        type,
        scene: page,
        sortable: false,
      };

      if (this.isDetails) {
        item.type = 'scene_link';
        delete item.label;
        delete item.sortable;
      }

      return item;
    },
    emitAddItem({ item, event }) {
      if (item.type === 'action_link') {
        logEvent('action_link_add', {
          view_type: this.view.type,
        });
        trackEvent('action_link_add', {
          view_type: this.view.type,
        });
      }

      eventBus.$emit(`addViewItem.${this.view.key}.${this.localViewType}`, {
        item, event,
      });
    },
    listenForAddItemEnd() {
      eventBus.$off(`addViewItemEnd.${this.view.key}.${this.localViewType}`);
      eventBus.$on(`addViewItemEnd.${this.view.key}.${this.localViewType}`, this.onAddItem);
    },
    onClickItem(event, isDisabled) {
      if (isDisabled) {
        return false;
      }

      const item = this.setItem(event);

      // TODO: standardize these!!! tables track these as type = link, others track as scene_link
      const isAPageLink = ((item.type === 'scene_link' && this.localViewType !== 'table') || (item.type === 'link' && this.localViewType === 'table'));

      if (isAPageLink && !item.scene) {
        this.addLinkedPageStart();

        return;
      }

      this.listenForAddItemEnd();

      this.emitAddItem({
        item,
      });
    },
    onAddItem({
      item, groupIndex, columnIndex, itemIndex,
    }) {
      // after adding a new Action Link item,
      // immediately open its Detail / Item Properties editor
      if (item.type === 'action_link') {
        if (this.localViewType === 'table') {
          return this.$router.push(`${this.$route.path}/${itemIndex}/properties`);
        }

        // Jon Note: This is a rough fix just to get it not throwing errors.
        // I had to add `layouts/0` to the url. It is possible the 0 needs to be some value...
        this.$router.push(`${this.$route.path}/layouts/0/groups/${groupIndex}/columns/${columnIndex}/items/${itemIndex}`);
      }
    },
  },
};
</script>

<style lang="scss">
.items-list {
  transition: all 300ms ease-in;
}

#view-add-items {

  .buttonSquare-outline {
    margin-left: 4px;
    display: none;
  }

  .toggle-trigger.open .buttonSquare-outline {
    display: flex;
  }

  .k-itemIcon {
    width: 16px;
    height: 16px;
    margin-right: 6px;
    opacity: 0.8;
  }

  .is-disabled {
    opacity: .35;

    a:hover {
      cursor: default !important;
    }
  }

  .tree-expand {
    height: 18px;
    width: 18px;
    margin-top: 1px;
    cursor: pointer;

    svg {
      display: block;
      margin-top: 3px;
      transition: all 200ms ease-out;
      width: 13px; height: 13px;
    }
  }

  .tree-expand.open svg {
    transform: rotate(90deg);
  }

  p {
    font-size: .9em;
    margin-bottom: 1.25em;
  }
  h3 {
    color: #761a66;
    font-weight: 500;
    margin-bottom: 0em;
    align-items: flex-start;
    font-size: 1rem;

    > .toggle-title span {
      text-transform: none;
      font-size: 1rem;
    }

    > .toggle-title .v-popover {
      display: inline-block;
      margin-left: 4px;
      margin-right: 14.4px;
    }

    > div {
      display: flex;
      align-items: center;
    }

    div.connection-title {
      flex-direction: column;
      align-items: self-start;
    }

    .connection {
      margin-top: 2px;
      font-size: .86rem;
      line-height: 1.25em;
      color: #2c3e50;
      font-weight: 400;
      opacity: .8;
      display: flex;
      align-items: center;
      text-transform: none;

      svg {
        width: 13px;
        height: 13px;
        opacity: .8;
        top: 0;

        &.icon-chevron-single {
          transform: rotate(-90deg);
        }
      }

      .icon-connection {
        margin-right: 5px;
      }

      .icon-chevron-single {
        margin: 0 1px;
      }
    }
  }

  div.add-items-list  {
    margin-bottom: .75em;
  }

  a {
    background-color: #e2e4ea;
    padding: 6px 12px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    color: $black;
    font-size: .96em;
    transition: background-color .2s ease-in;
    cursor: move;
  }
  a:hover {
    background-color: #d9dbe1;

    .k-itemIcon {
      color: $fuchsia-text;
    }
  }

  .is-dragging a {
    opacity: .5;
  }
  .expandable-items {
    overflow: hidden;
  }
}

#add-item-filter-links {
  a {
    margin-right: 8px;
    padding: 4px 12px;
    color: #313133;
    cursor: pointer;

    &:hover {
      color: $fuchsia-text;
    }

    b {
      color: $fuchsia-text;
    }
  }
}

// wrapped by another toggle?
.toggle-content #view-add-items h3 {
  border-top: 0;
  margin-top: 0;
}
</style>

<style lang="scss" scoped>
.items-wrapper {
  :deep(.toggle-title) {
    width: 100%;
  }
}

.title-wrapper {
  display: flex;
  max-width: 100%;
  width: 100%;
  flex-wrap: wrap;
  justify-content: space-between;

  .connection {
    flex-shrink: 0;
    margin-right: 14.4px;
  }
}

.title-label-wrapper {
  display: flex;
  flex-grow: 1;
  flex-basis: auto;
}

// The add item buttons need different styling to prevent them from overflowing the left nav.
.knButton.buttonFilter_button {
  margin-right: .5rem;
  flex-shrink: 0;
  padding: 0 .5rem;
  line-height: 1;
  height: 1.75rem;

  &:last-child {
    margin-right: 0;
  }
}
</style>
