<template>
  <Modal
    title="Add Record"
    :back="backLink"
    :prevent-background-click-to-close="true"
  >
    <FormWrapper :errors="localErrors">
      <Form
        class="kn-form-live"
        :inputs="inputs"
        :values="defaultRecordValues"
        :errors="fieldErrors"
        @submit-form="onSubmitForm"
      />
    </FormWrapper>
  </Modal>
</template>

<script>
import Modal from '@/components/ui/Modal';
import FieldUtils from '@/components/fields/FieldUtils';
import RequestUtils from '@/components/util/RequestUtils';
import RecordsUtils from '@/components/records/RecordsUtils';
import Form from '@/components/renderer/form/FormFields';
import FormWrapper from '@/components/ui/FormWrapper';
import { getAssetsFromValues } from '@/util/AssetsHelper';
import { eventBus } from '@/store/bus';
import { logEvent } from '@/lib/segment-helper';
import { trackEvent } from '@/lib/rudderstack-helper';

export default {
  name: 'RecordAdd',
  components: {
    Modal,
    FormWrapper,
    Form,
  },
  mixins: [
    FieldUtils,
    RequestUtils,
    RecordsUtils,
  ],
  data() {
    return {
      localErrors: [],
    };
  },
  watch: {
    errors: {
      handler(newErrors) {
        this.localErrors = [...this.localErrors, ...newErrors];
      },
    },
  },
  methods: {
    onSubmitForm(recordValues, formErrors) {
      this.localErrors = [];

      if (formErrors) {
        this.localErrors = [...this.localErrors, ...formErrors];
        return;
      }

      const assets = getAssetsFromValues(this.inputs, this.defaultRecordValues);

      this.commitRequest({
        request: () => window.Knack.Api.createRecord(this.objectKey, recordValues, assets),
        onSuccess: (record) => {
          logEvent('created_record');
          trackEvent('created_record');

          eventBus.$emit('recordCreate', record);

          this.$router.push(this.backLink);
        },
      });
    },
  },
};
</script>
