<template>
  <div class="tabMenu p-0">
    <RouterLink
      v-tippy
      :content="`${objectName} Fields`"
      :to="`/schema/list/objects/${objectKey}`"
      class="tabLink mr-2"
      :class="[
        {
          'is-active bg-brand-100': (activeType === 'fields'),
          'hover:bg-brand-50': (activeType !== 'fields'),
        },
        ribbonSpacingClass,
        baseAnchorButtonClasses
      ]"
    >
      <span class="tabLink_content m-0">
        <Icon
          type="fields"
          class="tabLink_icon h-4 w-4"
          :class="{
            'fill-[url(#svg-brand-gradient)]': (activeType === 'fields'),
          }"
        />
        <span
          class="tabLink_textLabel pl-1 text-base text-default font-normal"
          :class="{
            'text-emphasis': (activeType === 'fields'),
          }"
        >
          Fields
        </span>
      </span>
    </RouterLink>
    <RouterLink
      v-tippy
      :content="`${objectName} Records`"
      :to="`/records/objects/${objectKey}`"
      class="tabLink mr-2"
      :class="[
        {
          'is-active bg-brand-100': (activeType === 'records'),
          'hover:bg-brand-50': (activeType !== 'records'),
        },
        ribbonSpacingClass,
        baseAnchorButtonClasses
      ]"
    >
      <span class="tabLink_content m-0">
        <Icon
          type="database-records"
          class="tabLink_icon h-4 w-4"
          :class="{
            'fill-[url(#svg-brand-gradient)]': (activeType === 'records'),
          }"
        />
        <span
          class="tabLink_textLabel pl-1 text-base text-default font-normal"
          :class="{
            'text-emphasis': (activeType === 'records'),
          }"
        >
          Records
        </span>
      </span>
    </RouterLink>
    <IfPlan :level-is-minimum-of="2">
      <RouterLink
        v-slot="{ isActive }"
        v-tippy
        :content="`${objectName} Tasks`"
        data-cy="quick-nav-tasks"
        :to="`/tasks/objects/${objectKey}`"
        class="tabLink"
        :class="[
          {
            'is-active bg-brand-100': (activeType === 'tasks'),
            'hover:bg-brand-50': (activeType !== 'tasks'),
          },
          ribbonSpacingClass,
          baseAnchorButtonClasses
        ]"
      >
        <span class="tabLink_content m-0">
          <Icon
            type="action-task-2"
            class="tabLink_icon h-4 w-4"
            :class="{
              'fill-[url(#svg-brand-gradient)]': (activeType === 'tasks'),
            }"
          />
          <span
            class="tabLink_textLabel pl-1 text-base text-default font-normal"
            :class="{
              'text-emphasis': (activeType === 'tasks'),
            }"
          >
            Tasks
          </span>
          <CountIndicator
            v-if="taskCount"
            class="text-default"
            :class="{
              'bg-muted': !isActive,
              'bg-brand-50 text-emphasis': isActive
            }"
            :count="taskCount"
          />
        </span>
      </RouterLink>
    </IfPlan>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import CountIndicator from '@/components/ui/CountIndicator';
import Icon from '@/components/ui/Icon';
import IfPlan from '@/components/util/IfPlan';

export default {
  components: {
    CountIndicator,
    Icon,
    IfPlan,
  },
  props: {
    object: {
      type: Object,
      default: () => ({}),
    },
    activeType: {
      type: String,
      default: 'fields',
    },
  },
  computed: {
    ...mapGetters('ui', [
      'ribbonSpacingClass',
    ]),
    taskCount() {
      if (!this.object.tasks) {
        return 0;
      }

      return this.object.tasks.length;
    },
    objectKey() {
      return this.object.key;
    },
    objectName() {
      return this.object?.inflections?.singular;
    },
    baseAnchorButtonClasses() {
      return 'flex justify-center items-center rounded-lg m-0 font-normal text-base capitalize px-2 py-1.5';
    },
  },
};
</script>

<style lang="scss">
.objectLinks {
  align-items: center;
  display: none;
  justify-content: center;

  height: 100%;
  margin-left:.65em;

  @include respondMin(xl) {
    display: flex;
  }

  &_link {
    display: flex;
    align-items: center;

    margin-right: 10px;

    line-height: 1em;
    color: #929b9f;

    &:last-child {
      margin-right: 0;
    }

    &.is-active {
      color: $fuchsia;
    }

    &-schema {
      padding-right: 2px;
    }
  }

  &_icon {
    height: 19px !important;
    width: 19px !important;
  }
}
</style>
