import { initIntercom } from '@mathieustan/vue-intercom';

const appId = process.env.VUE_APP_INTERCOM_APP_ID;

const intercom = appId ? initIntercom({ appId }) : null;

function rebootIntercom(user) {
  intercom.shutdown();

  if (intercom.isBooted) return;
  intercom.boot(user);
}

export function showIntercom() {
  intercom.show();
}

// eslint-disable-next-line import/prefer-default-export
export async function startIntercomMessenger() {
  if (!process.env.VUE_APP_INTERCOM_APP_ID) return;
  const {
    email, first_name, last_name, user_id,
  } = window.Knack.Api;
  const user = {
    email,
    name: `${first_name} ${last_name}`,
    userId: user_id,
  };
  if (!intercom.ready) {
    intercom.once('ready', () => rebootIntercom(user));
  } else {
    rebootIntercom(user);
  }
}
