<template>
  <ConfirmModal
    title="Enable E-Commerce"
    confirm-text="Enable"
    @confirm="onClickEnableEcommerce"
    @cancel="onClose"
    @close="onClose"
  >
    <p>This will enable E-Commerce on your app, allowing you to charge your users or store their payment methods for future payments.</p>

    <p>
      <a
        class="underline text-default"
        href="https://learn.knack.com/article/sfbmn6ovpy-about-e-commerce"
        target="_blank"
      >
        Learn more about E-Commerce
      </a>
    </p>
  </ConfirmModal>
</template>

<script>
import ConfirmModal from '@/components/ui/ConfirmModal';
import RequestUtils from '@/components/util/RequestUtils';
import { logEvent } from '@/lib/segment-helper';
import { trackEvent } from '@/lib/rudderstack-helper';

export default {
  components: {
    ConfirmModal,
  },
  mixins: [
    RequestUtils,
  ],
  emits: ['close', 'enable'],
  methods: {
    onClickEnableEcommerce() {
      this.commitRequest({
        request: () => this.app.enableEcommerce(),
        onSuccess: () => {
          logEvent('ecom_activate');
          trackEvent('ecom_activate');

          this.$emit('enable');
        },
      });
    },
    onClose() {
      this.$emit('close');
    },
  },
};
</script>
