<template>
  <div
    id="kn-layout"
    class="builderLayout"
  >
    <GlobalLoading :is-loading="true" />
  </div>
</template>

<script>
import get from 'lodash/get';
import LogRocket from 'logrocket';
import createLogRocketPlugin from 'logrocket-vuex';
import VueSocketIO from 'vue-3-socket.io';
import { mapGetters } from 'vuex';

import GlobalLoading from '@/components/ui/GlobalLoading';
import { shouldEnableLogRocket } from '@/lib/env-helper';
import { logEvent } from '@/lib/segment-helper';
import { replaceUrlVariables } from '@/checklists/helpers';
import { trackEvent } from '@/lib/rudderstack-helper';

export default {
  name: 'AppLoading',
  components: {
    GlobalLoading,
  },
  computed: {
    ...mapGetters([
      'app',
    ]),
    appIsHippa() {
      return get(this.app, 'account.settings.hipaa.enabled') === true;
    },
  },
  mounted() {
    this.loadApp();
  },
  methods: {
    enableLogRocket(userAttributes) {
      if (!shouldEnableLogRocket() || this.appIsHippa) {
        return;
      }

      const logRocketAppId = this.app.isSQL() ? 'knack/knack-central' : 'knack/builder-jacwy';
      const logRocketRootHostname = this.app.isSQL() ? process.env.VUE_APP_LOGROCKET_ROOT_HOSTNAME ?? 'knack.com' : null;

      LogRocket.init(logRocketAppId, {
        rootHostname: logRocketRootHostname,
        network: {
          requestSanitizer: (request) => {
            const url = request.url.toLowerCase();
            if (url.includes('session') || url.includes('records')) {
              return {
                ...request,
                body: null,
              };
            }

            return request;
          },
          responseSanitizer: (response) => {
            const url = response.url.toLowerCase();
            if (url.includes('records')) {
              return {
                ...response,
                body: null,
              };
            }

            return response;
          },
        },
        release: process.env.VUE_APP_GIT_SHA,
        shouldRecordPerformance: true,
        console: {
          shouldAggregateConsoleErrors: true,
        },
      });

      this.$store.subscribe(({ type }) => LogRocket.track(type));

      const logRocketPlugin = createLogRocketPlugin(LogRocket);
      logRocketPlugin(this.$store);

      LogRocket.identify(window.Knack.Api.user_id, userAttributes);

      LogRocket.getSessionURL((sessionURL) => {
        if (!sessionURL) {
          return;
        }

        logEvent('LogRocket Session', {
          url: sessionURL,
        });
        trackEvent('LogRocket Session', {
          url: sessionURL,
        });
      });
    },
    initSocketIO(application) {
      const store = this.$store;

      // Don't get socketio options for forceNew and reconnection delay here
      // Also don't get query object so have to build a query param string instead
      const connection = `${process.env.VUE_APP_SOCKETS_URI}:${process.env.VUE_APP_SOCKETS_PORT}?appid=${application.id}&type=browser`;
      const vueSocketIoOptions = {
        debug: true,
        connection,
        vuex: {
          store,
          actionPrefix: 'SOCKET_',
          mutationPrefix: 'SOCKET_',
        },
      };

      // TODO: Determine if need to move this to its own state.isSocketConnected conditional or something
      this.$.appContext.app.use(new VueSocketIO(vueSocketIoOptions));
    },
    async processChecklistStep() {
      const params = new URLSearchParams(window.location.search);
      const stepId = params.get('checklist_step');
      const step = this.$store.getters['checklists/steps'].find((s) => s.definition.id === stepId);
      this.$store.commit('checklists/setStepFromDashboard', stepId);

      if (step && step.definition.type === 'in-product') {
        const { link } = step.definition.extra;

        const [area, url, features] = link.split('::');

        if (area !== 'builder') return;

        if (features) {
          this.$store.commit('checklists/setHighlightedFeatures', features.split(','));
        }

        if (url === '{current}') return;

        const replacedUrl = replaceUrlVariables(url, this.$store);

        await this.$router.replace(`/${replacedUrl}`);
      }
    },
    async loadApp() {
      const route = this.$route;
      const router = this.$router;
      const store = this.$store;

      const splitPathname = window.location.pathname.split('/');
      const accountSlug = splitPathname[1];
      const applicationSlug = splitPathname[2];

      const initResponse = await store.dispatch('bootstrapApp', {
        accountSlug,
        applicationSlug,
      });

      if (initResponse.redirect) {
        await router.replace(initResponse.redirect);
        return;
      }

      const { application } = initResponse.builderData;

      document.title = `${application.name} - Knack Builder`;

      const userAttributes = {
        name: `${window.Knack.Api.first_name} ${window.Knack.Api.last_name}`,
        email: window.Knack.Api.email,
        account_id: application.account.id,
        plan_id: application.account.product_plan.id,
      };

      this.enableLogRocket(userAttributes);

      this.initSocketIO(application);

      const redirectTo = route.query?.redirect || '/';

      // Forward back to the previous route.
      await router.replace(redirectTo);

      await this.processChecklistStep();
    },
  },
};
</script>

<style lang="scss">
#page-wrapper {
  flex-grow: 1;
  height: 100%;
}

#page-loading {
  display: flex;
  flex-direction: column;
  height: 100%;
}

#pages-wrapper {
  flex-grow: 1;
  height: 100%;
}

#page-wrapper {
  Transition: all 200ms ease-in;
}
</style>
