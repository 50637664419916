<template>
  <WizardStep
    :wizard-step="wizardStep"
    :step="step"
    @update:wizardStep="$emit('update:wizardStep', step)"
  >
    <template #intro>
      <div class="mb-2">
        Summary
      </div>
    </template>
    <p v-if="showEcommerceEnableMessage">
      <strong>E-Commerce features</strong> will be enabled on your app.
    </p>
    <div v-if="showPaymentMethodMessage">
      <p>A <strong>Payment Methods</strong> object will be added to your app to store information about the payment methods saved</p>
      <p>
        New <strong>user pages</strong> will be added to allow the logged-in user to view their charges and update their payment info. You will be able to customize or remove these pages. <a
          href="https://learn.knack.com/article/pikril8gmn-user-pages"
          class="underline text-default"
        >Click here</a> to learn more about user pages.
      </p>
    </div>
    <p v-if="showSourceObjectPaymentConnectionMessage">
      A connection field called <strong>Payment</strong> will be added to your <strong>{{ sourceObject.name }}</strong> object. This will store the connection to the payment record created by this view when attempting to create a charge.
    </p>
    <p v-if="!isNewView">
      These changes will be <strong>staged</strong> and you will need to hit "save changes" in the toolbox to fully save.
    </p>
    <button
      type="button"
      class="button green-fill margin-top-sm p-3 rounded-lg border border-solid border-default bg-white text-emphasis h-10 m-0 hover:bg-brand-50 hover:border-brand-600"
      @click="onNext"
    >
      {{ submitText }}
    </button>
  </WizardStep>
</template>

<script>
import { mapGetters } from 'vuex';
import hasIn from 'lodash/hasIn';
import get from 'lodash/get';
import WizardStep from '@/components/ui/WizardSection';
import PaymentUtils from '@/components/settings/ecommerce/PaymentUtils';
import RequestUtils from '@/components/util/RequestUtils';
import { logEvent } from '@/lib/segment-helper';

export default {
  components: {
    WizardStep,
  },
  mixins: [
    PaymentUtils,
    RequestUtils,
  ],
  props: {
    view: {
      type: Object,
      required: true,
    },
    step: {
      type: Number,
      required: true,
    },
    wizardStep: {
      type: Number,
      required: true,
    },
  },
  emits: [
    'submit',
    'update:wizardStep',
  ],
  computed: {
    ...mapGetters([
      'app',
      'objects',
      'getObjectByRole',
    ]),
    submitText() {
      if (this.isNewView) {
        return this.view.type === 'customer' ? 'Add Payment Method View' : 'Add Payment View';
      }

      return 'Stage Changes';
    },
    isNewView() {
      return this.view.key === 'new';
    },
    showEcommerceEnableMessage() {
      return !this.app.attributes.ecommerce.enabled;
    },
    showPaymentMethodMessage() {
      const isUsersEnabled = this.app.users.enabled;
      const isCustomerOptional = get(this.view, 'attributes.customer_optional', false);
      const hasChargeCustomer = hasIn(this.view, 'attributes.charge_customer');
      const hasPaymentMethodObject = this.objects.some((obj) => obj.attributes.ecommerce_payment_methods);

      return isUsersEnabled && (isCustomerOptional || hasChargeCustomer) && !hasPaymentMethodObject;
    },
    showSourceObjectPaymentConnectionMessage() {
      const isNotCustomerView = this.view.type !== 'customer';
      const hasConnectionField = this.sourceObject.fields.some((field) => field.attributes.ecommerce);

      return !hasConnectionField && isNotCustomerView;
    },
  },
  methods: {
    enableEcommerce() {
      this.commitRequest({
        request: () => this.app.enableEcommerce(),
        onSuccess: () => {
          logEvent('ecom_activate');
          trackEvent('ecom_activate');

          if (this.showSourceObjectPaymentConnectionMessage) {
            return this.connectSourceWithPaymentObject();
          }

          if (this.showPaymentMethodMessage) {
            return this.enablePaymentMethods();
          }

          return this.$emit('submit');
        },
      });
    },
    connectSourceWithPaymentObject() {
      const paymentObject = this.objects.find((obj) => obj.ecommerce);

      this.commitRequest({
        request: () => this.app.createPaymentField(this.sourceObject.key, paymentObject.key),
        onSuccess: () => {
          if (this.showPaymentMethodMessage) {
            return this.enablePaymentMethods();
          }

          return this.$emit('submit');
        },
      });
    },
    enablePaymentMethods() {
      this.commitRequest({
        request: () => this.app.enablePaymentMethods(),
        onSuccess: () => this.$emit('submit'),
      });
    },
    onNext() {
      if (this.showEcommerceEnableMessage) {
        return this.enableEcommerce();
      }

      if (this.showSourceObjectPaymentConnectionMessage) {
        return this.connectSourceWithPaymentObject();
      }

      if (this.showPaymentMethodMessage) {
        return this.enablePaymentMethods();
      }

      this.$emit('submit');
    },
  },
};
</script>

<style lang="scss">
</style>
