const MIN_RECORDS_SHOW_PAGINATION = 0;

export default {
  props: {
    objectKey: {
      type: String,
      default: '',
    },
    allowLimit: {
      type: Boolean,
      default: false,
    },
    recordsKeywordSearch: {
      type: String,
      default: '',
    },
    recordsFilters: {
      type: Object,
      default: () => ({
        match: 'and',
        rules: [],
      }),
    },
    currentRecordsPage: {
      type: Number,
      default: 1,
    },
    recordsPerPage: {
      type: Number,
      default: 25,
    },
    totalRecords: {
      type: Number,
      default: 0,
    },
    totalRecordsPages: {
      type: Number,
      default: 0,
    },
    showExportButton: {
      type: Boolean,
      default: true,
    },
    showPagination: {
      type: Boolean,
      default: true,
    },
    showFilters: {
      type: Boolean,
      default: true,
    },
    showFilterMenu: {
      type: Boolean,
      default: true,
    },
    showKeywordSearch: {
      type: Boolean,
      default: true,
    },
    currentRecordsCount: {
      type: Number,
      default: 0,
    },
  },
  emits: ['update'],
  data() {
    return {
      recordsNavMixin_localSearch: this.recordsKeywordSearch,
    };
  },
  computed: {
    recordsNavMixin_displaySettings() {
      return {
        paginationRange:
          this.showPagination
          && this.totalRecords
          && this.totalRecords > MIN_RECORDS_SHOW_PAGINATION,
        pagination: this.showPagination && this.totalRecords > 10,
        exportButton: this.showExportButton && this.currentRecordsCount,
        filters: this.showFilters,
        filterMenu: this.showFilterMenu,
        keywordSearch: this.showKeywordSearch,
      };
    },
    recordsNavMixin_localCurrentPage: {
      get() {
        return this.currentRecordsPage;
      },
      set(newValue) {
        this.recordsNavMixin_triggerUpdate({
          currentPage: Number(newValue),
        });
      },
    },
    recordsNavMixin_localRecordsPerPage: {
      get() {
        return this.recordsPerPage;
      },
      set(newValue) {
        this.recordsNavMixin_triggerUpdate({
          recordsPerPage: Number(newValue),
        });
      },
    },
    recordsNavMixin_localFilters: {
      get() {
        return this.recordsFilters;
      },
      set(newValue) {
        this.recordsNavMixin_triggerUpdate({
          filters: newValue,
        });
      },
    },
  },
  watch: {
    recordsKeywordSearch(newVal) {
      this.recordsNavMixin_localSearch = newVal;
    },
  },
  methods: {
    recordsNavMixin_triggerUpdate(updates = {}) {
      const update = {
        search: this.recordsNavMixin_localSearch,
        currentPage: this.recordsNavMixin_localCurrentPage,
        recordsPerPage: this.recordsNavMixin_localRecordsPerPage,
        filters: this.recordsNavMixin_localFilters,
        ...updates,
      };

      this.$emit('update', update);
    },
  },
};
