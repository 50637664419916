import { mapGetters } from 'vuex';
import { logEvent } from '@/lib/segment-helper';
import { trackEvent } from '@/lib/rudderstack-helper';

export default {
  watch: {
    'app.design': {
      handler() {
        // This ensures the style sheet generated by the Style Engine reflects these changes for any previews
        this.app.buildDesignStyleSheet();
      },
      deep: true,
    },
  },
  data() {
    return {
      colorHasChanged: false,
    };
  },
  computed: {
    ...mapGetters([
      'app',
      'applicationHasPendingDesignChanges',
    ]),
  },
  created() {
    // Check if this is the first change
    if (!this.applicationHasPendingDesignChanges) {
      // Create our backup so we can restore with any cancels
      this.$store.dispatch('createAppDesignSettingsBackup');
    }
  },
  methods: {
    ...mapGetters([
      'updateApplication',
    ]),
    onSaveAppDesignSettings() {
      const update = {
        design: this.$store.state.app.design,
      };

      this.commitRequest({
        request: () => this.app.update(update),
        onSuccess: (response) => {
          logEvent('saved_design_settings');
          trackEvent('saved_design_settings');

          if (this.colorHasChanged) {
            logEvent('colors_changed');
            trackEvent('colors_changed');

            this.colorHasChanged = false;
          }

          this.$store.commit('setApplicationHasPendingDesignChanges', false);

          // Create our backup so we can restore with any cancels
          this.$store.dispatch('createAppDesignSettingsBackup');

          // Confirmed save from navigation guard, so continue to that path
          if (this.navigationGuard.showConfirm) {
            this.navigationGuard.showConfirm = false;

            return this.$router.push(this.navigationGuard.path);
          }
        },
      });
    },
    onCancelAppDesignSettings() {
      this.$store.dispatch('restoreAppDesignSettingsBackup');

      if (this.navigationGuard.showConfirm) {
        this.navigationGuard.showConfirm = false;
      }
    },
    onColorHasChanged() {
      this.colorHasChanged = true;
    },
  },
};
