import { RudderAnalytics } from '@rudderstack/analytics-js';
import hasIn from 'lodash/hasIn';
import get from 'lodash/get';
import isNil from 'lodash/isNil';
import store from '@/store';
import { isTestingEmail } from '@/lib/segment-helper';
import { getFlagsmithEnabledFlags } from '@/lib/flagsmith-helper';

const isRudderStackEnabled = () => !!process.env.VUE_APP_RUDDERSTACK_WRITE_KEY
  && !!process.env.VUE_APP_RUDDERSTACK_DATA_PLANE_URL
  && (!hasIn(window, 'Cypress') || store.state.app.settings.enableSegment);

const rudderAnalytics = new RudderAnalytics();

window.isRudderStackLoaded = false;

const loadRudderStack = async () => {
  console.log(window.isRudderStackLoaded, !isRudderStackEnabled(), isNil(rudderAnalytics.load));
  if (window.isRudderStackLoaded || !isRudderStackEnabled() || isNil(rudderAnalytics.load)) {
    return;
  }

  rudderAnalytics.load(
    process.env.VUE_APP_RUDDERSTACK_WRITE_KEY,
    process.env.VUE_APP_RUDDERSTACK_DATA_PLANE_URL,
    {},
  );

  window.isRudderStackLoaded = true;

  const account = store.state.app.get('account');

  const appName = store.state.app.get('name');

  const appSlug = store.state.app.get('slug');

  const planName = get(account, 'product_plan.name', 'Trial');

  const monthlySpend = get(account, 'product_plan.price', 0);

  const planRecords = get(account, 'product_plan.records');

  const planApps = get(account, 'product_plan.apps');

  const v3Only = get(account, 'settings.v3_only') === true;

  const accountSlug = get(account, 'slug');

  if (isTestingEmail(Knack.Api.email)) return;

  const accountMeta = {
    name: account.name,
    plan: planName,
    monthly_spend: monthlySpend,
    plan_records: planRecords,
    plan_apps: planApps,
    referral: account.referral,
  };

  const meta = {
    email: Knack.Api.email,
    name: `${Knack.Api.first_name} ${Knack.Api.last_name}`,
    company: {
      id: Knack.Api.account_id,
      ...accountMeta,
    },
    v3_only: v3Only,
    app_slug: appSlug,
    app_name: appName,
    v3_account_slug: accountSlug,
    plan: planName,
    traits: {
      email: Knack.Api.email,
      plan: planName,
      monthly_spend: monthlySpend,
      plan_records: planRecords,
      plan_apps: planApps,
    },
  };

  await new Promise((resolve) => {
    rudderAnalytics.identify(Knack.Api.user_id, meta, async () => {
      rudderAnalytics.group(Knack.Api.account_id, {
        ...accountMeta,

        // Add flagsmith_ prefix to all flag names to avoid confusion
        ...Object.fromEntries(
          Object.entries(await getFlagsmithEnabledFlags())
            .map(([k, v]) => [`flagsmith_${k}`, v]),
        ),
      }, () => resolve());
    });
  });
};

const trackEvent = async (event, metadata) => {
  if (!isRudderStackEnabled() || !store.state.app || !store.state.app.get || isTestingEmail(Knack.Api.email)) {
    return;
  }

  await loadRudderStack();

  const account = store.state.app.get('account');

  const extendedMetadata = {
    groupId: Knack.Api.account_id,
    account_id: Knack.Api.account_id,
    application_id: Knack.Api.application_id,
    plan: get(account, 'product_plan.name', 'Trial'),
    monthly_spend: get(account, 'product_plan.price', 0),
    ...metadata,
  };

  if (hasIn(rudderAnalytics, 'track')) {
    rudderAnalytics.track(event, extendedMetadata);
  }
};

const trackPage = async () => {
  if (!isRudderStackEnabled() || !store.state.app || !store.state.app.get || isTestingEmail(Knack.Api.email)) {
    return;
  }

  await loadRudderStack();

  if (hasIn(rudderAnalytics, 'page')) {
    rudderAnalytics.page();
  }
};

export { trackPage, trackEvent };
