import hasIn from 'lodash/hasIn';
import { mapGetters } from 'vuex';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import isFunction from 'lodash/isFunction';
import isString from 'lodash/isString';
import get from 'lodash/get';
import template from 'lodash/template';

import { handleChanges } from '@/lib/response-helper';
import { logEvent } from '@/lib/segment-helper';
import { trackEvent } from '@/lib/rudderstack-helper';

export default {
  data() {
    return {
      errors: [],
      notifyErrors: false,
    };
  },
  computed: {
    ...mapGetters([
      'app',
    ]),
  },
  watch: {
    errors(newValue) {
      if (!this.$el || !isFunction(this.$el.querySelectorAll)) {
        return;
      }

      if (newValue === undefined || newValue === null) {
        return;
      }

      // reset existing error highlighting
      this.$el.querySelectorAll('.has-error').forEach(($el) => $el.classList.remove('has-error'));

      for (const error of newValue) {
        if (isNil(error.context)) {
          continue;
        }

        const element = isEmpty(error.field) ? this.$el.querySelector(`[data-field-name=${error.context.key}]`) : this.$el.querySelector(`[data-field-name=${error.field}]`);

        if (!isNil(element)) {
          element.classList.add('has-error');
        }

        if (this.notifyErrors) {
          this.$notify({
            group: 'top-right',
            type: 'error',
            duration: 5000,
            'animation-type': 'velocity',
            title: 'Error',
            text: error.message,
            data: {
              titleIcon: 'error',
            },
          });
        }
      }
    },
  },
  methods: {

    commitRequest({
      validate = () => {}, onSuccess = (response) => response, onError = (errors) => errors, request, globalLoading = true, forceExecuteOnErrorCallback = false,
    }) {
      this.generator = this.commitRequestGenerator({
        validate,
        onSuccess,
        onError,
        request,
        globalLoading,
        forceExecuteOnErrorCallback,
      });

      this.generator.next();
    },

    async* commitRequestGenerator({
      validate = () => {}, onSuccess = (response) => response, onError = (errors) => errors, request, globalLoading = true, forceExecuteOnErrorCallback = false,
    }) {
      try {
        // reset errors
        this.errors = [];

        // validate
        const validationResult = await validate();

        // An `errors` result comes from the validation-helper:validate() function.
        // While `error` comes from manually validating (which is deprecated).
        if (!isEmpty(validationResult?.errors)) {
          const validationError = new Error('ValidationError');
          validationError.errors = validationResult.errors;

          throw validationError;
        } else if (hasIn(validationResult, 'error') && !isEmpty(validationResult.error)) {
          const validationError = new Error('ValidationError');

          validationError.errors = validationResult.error.details;

          if (!isEmpty(validationResult.errorMap)) {
            for (const error of validationError.errors) {
              const field = error.path.join('_');
              const { type } = error;
              const errorKey = `error.${field}.${type}`;

              error.message = template(get(validationResult.errorMap, errorKey, error.message))(error);
            }
          }

          throw validationError;
        }

        // make the request as commiting a Vuex action
        if (globalLoading) {
          this.$store.commit('commitRequest');
        }

        let response = await request();

        if (response === 'reauthenticate') {
          this.$store.commit('setReauthenticationRequired');

          // Why do we need a conditional before calling this?
          if (globalLoading) {
            this.$store.commit('completeRequest');
          }

          this.findRef(this, 'reauthenticationLogin').externallyScopedRequestUtilsGenerator = this.generator;

          document.querySelector('#kn-login-overlay-background').style.display = null;

          yield;

          this.$store.commit('commitRequest');

          response = await request();
        }

        if (hasIn(response, 'changes')) {
          log('handling changes');

          await this.handleChanges(response.changes);
        }

        // send back to component for success handling
        if (globalLoading) {
          this.$store.commit('completeRequest');
        }

        return onSuccess(response);
      } catch (err) {
        log('RequestUtil caught error');
        log(err);
        console.trace();

        if (globalLoading) {
          this.$store.commit('completeRequest');
        }

        const isAppUnavailable = err?.status === 503
          && err?.errors?.some(({ message }) => message === 'Application unavailable, please try again later');

        if (isAppUnavailable) {
          return this.$notify({
            group: 'top-right',
            type: 'error',
            duration: 5000,
            'animation-type': 'velocity',
            title: 'App Unavailable',
            text: 'We are performing some maintenance at the moment. Please try again later.',
            data: {
              titleIcon: 'warning',
            },
          });
        }

        if (isString(err.message) && err.message.includes('Network Error')) {
          let requestUrl = 'unknown';

          if (err.config?.baseURL && err.config?.url) {
            requestUrl = `${err.config.baseURL}${err.config.url}`;
          }

          logEvent('Network Error', {
            sourceApp: 'v3 builder',
            sourceUrl: window.location.href,
            requestUrl,
            requestMethod: err.config?.method || 'unknown',
            status: err.status ?? err.request?.status ?? 'unknown',
          });
          trackEvent('Network Error', {
            sourceApp: 'v3 builder',
            sourceUrl: window.location.href,
            requestUrl,
            requestMethod: err.config?.method || 'unknown',
            status: err.status ?? err.request?.status ?? 'unknown',
          });

          if (forceExecuteOnErrorCallback) {
            onError(this.errors);
          }

          return this.$notify({
            group: 'top-right',
            type: 'error',
            duration: 5000,
            'animation-type': 'velocity',
            title: 'Network Error',
            text: 'Try refreshing the page and attempting the action again, or contact Knack support.',
            data: {
              titleIcon: 'warning',
            },
          });
        }

        if (hasIn(err, 'status') && hasIn(err, 'errors')) {
          const { status } = err;
          const { errors } = err;

          log('error status: ', status);
          log('errors: ', errors);

          this.errors = errors;
        }

        // error from validator
        this.errors = err.errors;

        log('errors now!', this.errors);

        onError(this.errors);
      } finally {
        this.generator = null;
      }
    },

    handleChanges,

    findRef(context, refKey) {
      if (hasIn(context, `$refs.${refKey}`)) {
        return context.$refs[refKey];
      }

      if (isEmpty(context.$parent)) {
        return null;
      }

      return this.findRef(context.$parent, refKey);
    },
  },
};
