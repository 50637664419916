import isEmpty from 'lodash/isEmpty';
import Api from '@/lib/api-wrapper';
import { NEW_PAGE_KEY } from '@/lib/page/page-constants';
import { mergedResponseChanges } from '@/lib/response-helper';
import { logEvent } from '@/lib/segment-helper';
import store from '@/store';
import { trackEvent } from '@/lib/rudderstack-helper';

const storeState = {};
const storeGetters = {};
const storeMutations = {};

/**
 * Chooses the best api call to make in order to create the given page.
 *
 * @param {Page} page
 * @param {object} loginVars
 * @returns {Promise}
 */
function apiCreatePage(page, loginVars) {
  const pageData = page.raw;
  const allowedProfiles = pageData.allowed_profiles;
  const parentSlug = pageData.parent;
  const pageName = pageData.name;
  const pageType = page.type;
  const { views } = pageData;
  const authenticated = page.isAuthenticated();
  const menuPages = (page.isMenuPage()) ? pageData.menu_pages : null;

  if (page.isUserPage()) {
    return Api.createUserPage(
      pageName,
      pageType,
      views,
      authenticated,
      loginVars,
      menuPages,
    );
  }

  if (page.isLoginPage()) {
    const newLoginVars = {
      authenticated: true,
      allowed_profiles: allowedProfiles,
      limit_profile_access: !isEmpty(allowedProfiles),
    };

    return Api.createLoginPage(pageName, views, newLoginVars);
  }

  return Api.createPage(
    pageName,
    pageType,
    views,
    authenticated,
    loginVars,
    menuPages,
    parentSlug,
  );
}

const storeActions = {

  /**
   * Updates the page settings.
   *
   * @param {object} context
   * @param {function(string, *)} context.commit
   * @param {function(string, *)} context.dispatch
   * @param {object} context.rootGetters
   * @param {object} payload
   * @param {string} payload.pageKey
   * @param {object} payload.updates
   * @returns {Promise}
   */
  async updateSettings({ commit, dispatch, rootGetters }, { pageKey, updates }) {
    /** @type {RawPage} page */
    const page = rootGetters.getPageByKey(pageKey);

    if (!page) {
      return;
    }

    const toUpdate = {
      ...page.raw,
      ...(updates || {}),
      views: page.views,
    };

    // if user sets limit_profile_access to false then reset allowed_profiles (v2 parity)
    const allowedProfiles = (!toUpdate.limit_profile_access) ? [] : toUpdate.allowed_profiles;

    // Explicitly pass undefined for the `authenticated` parameter. This is a valid
    // property on the page in the schema but in an update create a new parent
    // login page if included in the payload. A separate API wrapper method
    // (`updatePageLoginSettings`) is available to mark a scene as requiring login.
    const response = await Api.updatePage(
      pageKey,
      toUpdate.name,
      toUpdate.views,
      undefined,
      allowedProfiles,
      toUpdate.limit_profile_access,
      toUpdate.slug,
      (toUpdate.modal || false),
      (toUpdate.modal_prevent_background_click_close || false),
      toUpdate.page_menu_display,
      toUpdate.ignore_entry_scene_menu,
      toUpdate.icon,
      (toUpdate.print || false),
    );

    const newPage = response.scene || {};

    // This flow can't update views so don't reload them.
    delete newPage.views;

    // Make sure if the page slug changed that the page maps update.
    commit('page/updatePageSlug', { newSlug: updates.slug, oldSlug: page.raw.slug }, { root: true });

    await dispatch('updatePageLocally', { pageKey, updates: newPage }, { root: true });

    return response;
  },

  /**
   * Updates the page rules.
   *
   * @param {object} context
   * @param {function} context.dispatch
   * @param {object} context.rootGetters
   * @param {object} payload
   * @param {string} payload.pageKey
   * @param {array} [payload.rules]
   * @returns {Promise}
   */
  async updateRules({ dispatch, rootGetters }, { pageKey, rules = [] }) {
    /** @type {RawPage} page */
    const page = rootGetters.getPageByKey(pageKey);

    if (!page) {
      return;
    }

    const response = await Api.updatePageRules(pageKey, rules);

    if (response.success) {
      const updates = { rules };

      await dispatch('updatePageLocally', { pageKey, updates }, { root: true });
    }
  },

  /**
   * Creates a new page through the api.
   *
   * @param {object} context
   * @param {function} context.dispatch
   * @param {object} context.rootGetters
   * @param {object} payload
   * @param {Page} payload.page
   * @returns {Promise}
   */
  async create({ dispatch, rootGetters }, { page }) {
    if (!page) {
      throw new Error('Could not create new page because no Page object was provided.');
    }

    const authenticated = page.isAuthenticated();

    // is this the first page? If so we're going to delete this homepage so this new page can be the first one
    if (rootGetters.isEmptyPages()) {
      await Api.deletePage('scene_1');

      await dispatch('removePage', 'scene_1', { root: true });

      // TODO:: add a "home" attribute to the creating page? This will reset to scene_1
    }

    // loginVars currently required by route to add a login protected page :(
    // This must default to null or else page.type is ignored. The server seems to supersede login_vars over page.type === `menu`
    let loginVars = null;

    if (authenticated) {
      loginVars = {
        ...loginVars,
        authenticated: true,
        allowed_profiles: page.raw.allowed_profiles,
        limit_profile_access: !isEmpty(page.raw.allowed_profiles),
      };
    }

    const { app } = rootGetters;
    let userResponse = {};

    // Add users and merge into response changes if needed
    if (!app.users.enabled && authenticated) {
      logEvent('users_activate');
      trackEvent('users_activate');

      userResponse = await app.enableUsers();

      // this.$notify({
      //   group: 'main',
      //   type: 'success',
      //   duration: 3000,
      //   'animation-type': 'velocity',
      //   title: 'Success',
      //   text: 'Users have been added since you added a User Page',
      // });
    }

    // create page
    const response = await apiCreatePage(
      page,
      loginVars,
    );

    if (!_.isEmpty(userResponse)) {
      response.changes = mergedResponseChanges(userResponse, response);
    }

    const newSceneData = response.scene;

    // if this was a new login page created, the actual page is no longer of type = authentication
    // instead, the new parent page created was of that type, so we set this local type to page
    if (!newSceneData.type && page.type) {
      newSceneData.type = 'page';
    }

    // First remove the new page from the page cache, so we can add it fresh.
    await dispatch('removePage', NEW_PAGE_KEY, { root: true });

    // Update the page with the new data (this must be done AFTER the removePage call).
    page.updatePageSchema(newSceneData);

    // Add the page back into the page cache.
    const rawPage = await dispatch('addPage', page.raw, { root: true });

    return {
      ...response,
      rawPage,
    };
  },

  /**
   * Updates the menu pages for a page.
   *
   * @param {object} context
   * @param {function} context.dispatch
   * @param {object} context.rootGetters
   * @param {object} payload
   * @param {string} payload.pageKey
   * @param {object} payload.menuPages
   * @returns {Promise<object>}
   */
  async updateMenuPages({ dispatch, rootGetters }, { pageKey, menuPages }) {
    /** @type {RawPage} page */
    const page = rootGetters.getPageByKey(pageKey);

    if (!page) {
      return;
    }

    const response = Api.updatePageSettings(page.key, {
      name: page.name,
      menu_pages: menuPages,
      views: [],
    });

    const updates = { menu_pages: menuPages };

    await dispatch('updatePageLocally', { pageKey, updates }, { root: true });

    if (!isEmpty(menuPages)) {
      await dispatch('page/navVisibility/showNavChildren', pageKey, { root: true });
    } else {
      await dispatch('page/navVisibility/hideNavChildren', pageKey, { root: true });
    }

    return response;
  },

  /**
   * Updates the page layout and groups.
   *
   * @param {object} context
   * @param {object} context.rootGetters
   * @param {object} payload
   * @param {string} payload.pageKey
   * @returns {Promise}
   */
  async updateLayout({ rootGetters }, { pageKey }) {
    /** @type {RawPage} page */
    const page = rootGetters.getPageByKey(pageKey);

    if (!page) {
      return;
    }

    const viewsOrder = page.views.map((view) => view.key);

    return Api.updateViewOrder(pageKey, viewsOrder, page.groups);
  },

  /**
   * Updates the order of the pages when given the new order for a single depth.
   *
   * @param {function(string, *)} context
   * @param {object} payload
   * @param {string} payload.movedPageKey
   * @param {string[]} payload.sortedPageKeys
   * @returns {Promise<void>}
   */
  async updatePagesOrder({ commit }, { movedPageKey, sortedPageKeys }) {
    commit('commitRequest', undefined, { root: true });

    const response = await Api.updatePagesSort(sortedPageKeys);

    commit(
      'page/processNewPagesOrder',
      { movedPageKey, newOrder: sortedPageKeys },
      { root: true },
    );

    commit('completeRequest', undefined, { root: true });

    return response;
  },
};

export default {
  // Full namespace: page/api
  api: {
    namespaced: true,
    state: storeState,
    getters: storeGetters,
    mutations: storeMutations,
    actions: storeActions,
  },
};
