<script setup>
import { computed, onMounted } from 'vue';
import { useStore } from 'vuex';

import { useRouter } from 'vue-router';
import ObjectsEmpty from '@/components/objects/ObjectsEmpty';

const store = useStore();

const firstObject = computed(() => store.getters.standardObjects[0]);
const router = useRouter();

onMounted(() => {
  if (firstObject.value && router.currentRoute.value.path.replace(/\/+$/, '') === '/schema/list') {
    router.replace(`/schema/list/objects/${firstObject.value.key}`);
  }
});
</script>

<template>
  <div class="body-wrapper">
    <ObjectsEmpty v-if="!firstObject" />
  </div>
</template>
