import axios from 'axios';
import api from '@/lib/api-wrapper';

const storeState = {
  steps: [],
  highlightedFeatures: [],
  popoverOpen: false,
  stepFromDashboard: null,
  hasClosedChecklistModal: false,
};

const storeGetters = {
  steps: (state) => state.steps,
  data: (state) => state.data,
  highlightedFeatures: (state) => state.highlightedFeatures,
  popoverOpen: (state) => state.popoverOpen,
  stepFromDashboard: (state) => state.stepFromDashboard,
  hasClosedChecklistModal: (state) => state.hasClosedChecklistModal,
};

const storeMutations = {
  setChecklists(state, params) {
    Object.assign(state, params);
  },
  setHighlightedFeatures(state, features) {
    state.highlightedFeatures = [...features];
  },
  setPopoverOpen(state, isOpen) {
    state.popoverOpen = isOpen;
  },
  setHasClosedChecklistModal(state, hasClosedChecklistModal) {
    state.hasClosedChecklistModal = hasClosedChecklistModal;
  },
  setStepFromDashboard(state, step) {
    state.stepFromDashboard = step;
  },
};

const storeActions = {
  async init({ dispatch }) {
    await dispatch('fetchSteps');
  },

  async fetchSteps({ commit }) {
    const url = `${process.env.VUE_APP_API_URL}checklists/${api.user_id}`;
    const { data } = await axios.get(url, {}, {
      withCredentials: true,
    });

    const sections = Object.values(data?.checklist?.sections ?? {});

    const steps = [];

    sections.forEach((section) => {
      Object.values(section.steps).forEach((step) => {
        steps.push(step);
      });
    });

    commit('setChecklists', { steps, data: data?.checklist });
  },
};

export default {
  namespaced: true,
  state: storeState,
  getters: storeGetters,
  mutations: storeMutations,
  actions: storeActions,
};
