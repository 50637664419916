<template>
  <Toolbox
    back-title="All Pages"
    :back-count="2"
    :title="addTitle"
    class="no-padding"
    theme="add"
  >
    <PageAddWizard
      :add-type="addType"
      class="margin-bottom pb-4 mb-0"
      @addPage="onAddPage"
    />
  </Toolbox>
</template>

<script>
import Toolbox from '@/components/layout/Toolbox';
import PageAddWizard from '@/components/pages/PageAddWizard';
import RequestUtils from '@/components/util/RequestUtils';
import { logEvent } from '@/lib/segment-helper';
import { trackEvent } from '@/lib/rudderstack-helper';

export default {
  name: 'PageAdd',
  components: {
    Toolbox,
    PageAddWizard,
  },
  mixins: [
    RequestUtils,
  ],
  props: {
    addType: {
      type: String,
      default: 'public', // public | menu | user | login
    },
  },
  data() {
    return {};
  },
  computed: {
    addTitle() {
      const titles = {
        menu: 'Add a New Dropdown Menu',
        login: 'Add a New Login Page',
        user: 'Add a New Utility Page',
        default: 'Add a New Page',
      };

      return titles[this.addType] || titles.default;
    },
  },
  methods: {
    onAddPage(page) {
      this.commitRequest({
        request: () => this.$store.dispatch('page/api/create', { page }),
        onSuccess: async ({ rawPage }) => {
          logEvent('page_add', {
            page_name: rawPage.name,
            page_id: rawPage.key,
            page_login: rawPage.authenticated,
            page_type: 'quick_start',
          });
          trackEvent('page_add', {
            page_name: rawPage.name,
            page_id: rawPage.key,
            page_login: rawPage.authenticated,
            page_type: 'quick_start',
          });

          if (this.addType === 'menu') {
            this.$router.push(`/pages/${rawPage.key}`);

            return;
          }

          this.$router.push({
            path: `/pages/${rawPage.key}/views/add`,
            query: {
              confirmNewPage: true,
            },
          });
        },
      });
    },
  },
};
</script>

<style lang="scss">
.wizard-section:first-child {
  margin-top: 0;
  padding-top: 0;
  border: 0;
}
</style>
